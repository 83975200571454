import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lib-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.css']
})
export class UserDropdownComponent implements OnInit {

  @Input() state: 'fadeOut' | 'fadeIn' = 'fadeOut';
  @Output() logOut = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

}
