import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Observable, tap} from "rxjs";
import {UserService} from "src/app/services/user/user.service";
import {environment} from "src/environments/environment";

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toaster: ToastrService, private userService: UserService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem('idToken');
    const jwt = localStorage.getItem('jwtToken');
    let accessToken = "";
    let jwtToken = "";

    if (idToken && idToken !== 'undefined') {
      accessToken = idToken;
    }

    if (jwt && jwt !== 'undefined') {
      jwtToken = JSON.parse(jwt);
    }

    const userProfile = JSON.parse(localStorage.getItem("user_profile") || '{}');

    req = this.addHeader(req, 'Authorization', 'Bearer ' + (req.url.toLowerCase().includes('identity') ? accessToken : jwtToken));
    req = this.addHeader(req, 'Ocp-Apim-Subscription-Key', environment.apimSubscriptionKey);
    req = this.addHeader(req, 'User-Name', userProfile != null ? userProfile.mail : '');

    return next.handle(req).pipe(
      tap(
        event => this.handleResponse(req, event),
        error => this.handleError(req, error)
      )
    );
  }

  handleResponse(req: HttpRequest<any>, event) {
    /*console.log('Handling response for ', req.url, event);
    if (event instanceof HttpResponse) {
      console.log('Request for ', req.url,
          ' Response Status ', event.status,
          ' With body ', event.body);
    }*/
  }

  handleError(req: HttpRequest<any>, error) {
    if (error.status === HttpStatusCode.Unauthorized) {
      this.userService.logout();
    }
  }

  private addHeader(req: HttpRequest<any>, key: string, value: any) {
    return req.clone({
        setHeaders:
          {[key]: `${value}`}
      }
    );
  }
}
