import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity } from 'src/app/models/activity/activity';
import { AgreementTemplateRequest } from 'src/app/models/agreement-template/agreement-template';
import {
	AssociatedEntities,
	UpdateAssociatedClients,
	UpdateAssociatedEntities,
} from 'src/app/models/client/client';
import {
	ContractCategory,
	ContractType,
	ContractStatus,
} from 'src/app/models/project/project';
import { INewProposalVersion } from 'src/app/models/proposals/new-proposal-version.dto';
import {
	Contract,
	ContractDocument,
	ContractRequest,
	ContractStageUpdateDetail,
} from 'src/app/models/proposals/proposal';
import { ResponsePayloadDTO } from 'src/app/models/salesAPIResponse';
import { TableFilters } from 'src/app/shared/common/tableFilters';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ProposalService {
	constructor(private httpClient: HttpClient) { }
	getProposalById(id: number): Observable<ResponsePayloadDTO<Contract>> {
		return this.httpClient.get<ResponsePayloadDTO<Contract>>(
			`${environment.saleApiBaseUrl}/contracts/${id}`
		);
	}

	getProposalByArchiveId(id: number): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.put<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts/${id}/archive-status`,
			""
		);
	}

	submitManualAgreement(contractId: number, agreementDocumentUrl: string): Observable<{ clientId: number, contractId: number }> {
		return this.httpClient.post<{ clientId: number, contractId: number }>(`${environment.saleApiBaseUrl}/contracts/agreement/${contractId}/upload`, {
			agreementDocumentUrl,
		});
	}

	saveProposal(
		proposal: ContractRequest
	): Observable<ResponsePayloadDTO<number>> {
		return this.httpClient.post<ResponsePayloadDTO<number>>(
			`${environment.saleApiBaseUrl}/contracts`,
			proposal
		);
	}

	saveProposalVersion(
		proposal: INewProposalVersion
	): Observable<ResponsePayloadDTO<number>> {
		return this.httpClient.post<ResponsePayloadDTO<number>>(
			`${environment.saleApiBaseUrl}/contracts/version`,
			proposal
		);
	}

	updateproposal(
		proposal: ContractRequest
	): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.put<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts`,
			proposal
		);
	}

	deleteproposalById(id: number): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts/${id}`
		);
	}

	getProjectCategories(): Observable<ResponsePayloadDTO<ContractCategory[]>> {
		return this.httpClient.get<ResponsePayloadDTO<ContractCategory[]>>(
			`${environment.saleApiBaseUrl}/contracts/categories`
		);
	}

	getProjectTypes(): Observable<ResponsePayloadDTO<ContractType[]>> {
		return this.httpClient.get<ResponsePayloadDTO<ContractType[]>>(
			`${environment.saleApiBaseUrl}/contracts/types`
		);
	}

	getProjectStatus(): Observable<ResponsePayloadDTO<ContractStatus[]>> {
		return this.httpClient.get<ResponsePayloadDTO<ContractStatus[]>>(
			`${environment.saleApiBaseUrl}/contracts/status`
		);
	}

	getAllProposalsByDealId(
		id: number,
		archive?: boolean
	): Observable<ResponsePayloadDTO<Contract[]>> {
		if (archive === true) {
			return this.httpClient.get<ResponsePayloadDTO<Contract[]>>(
				`${environment.saleApiBaseUrl}/deals/${id}/proposals?archive=${archive}`
			);
		}

		return this.httpClient.get<ResponsePayloadDTO<Contract[]>>(
			`${environment.saleApiBaseUrl}/deals/${id}/proposals`
		);
	}

	getAllProposalVersionsById(
		id: number
	): Observable<ResponsePayloadDTO<Contract[]>> {
		return this.httpClient.get<ResponsePayloadDTO<Contract[]>>(
			`${environment.saleApiBaseUrl}/contracts/${id}/versions`
		);
	}

	updateCurrentState(id: number): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.put<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts/${id}`,
			id
		);
	}

	copyProposal(
		proposalId: number,
		proposalName: string
	): Observable<ResponsePayloadDTO<number>> {
		const params = new HttpParams()
			.set('proposalId', proposalId)
			.set('proposalName', proposalName);
		return this.httpClient.post<ResponsePayloadDTO<number>>(
			`${environment.saleApiBaseUrl}/contracts/copy`,
			null,
			{ params }
		);
	}

	getProposalActivitiesByProposalId(
		id: number,
		requireParentActivity,
		tableFilter: TableFilters,
		activityCategoryId: number = 0,
		isFromContractActivities: boolean = false
	): Observable<ResponsePayloadDTO<Activity[]>> {

		return this.httpClient.get<ResponsePayloadDTO<Activity[]>>(
			`${environment.projectApiBaseUrl}/contract-activities/contract/${id}?requireParentActivity=${requireParentActivity}
			&Page=${tableFilter?.page}&PageSize=${tableFilter?.pageSize}&Search=${tableFilter?.search}&Sort=${tableFilter?.sort}
			&IsAscending=${tableFilter?.isAscending}&ActivityCategoryId=${activityCategoryId}&isFromContractActivities=${isFromContractActivities}`,
			{
				params: tableFilter?.additionalQueryParams ?? undefined,
			}
		);
	}

	uploadContractDocument(
		contractDocument: any
	): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.post<ResponsePayloadDTO<any>>(
			`${environment.saleApiBaseUrl}/contracts/document`,
			contractDocument
		);
	}

	getAllContractDocumentsByID(ID: number): Observable<
		ResponsePayloadDTO<ContractDocument[]>
	> {
		return this.httpClient.get<ResponsePayloadDTO<ContractDocument[]>>(
			`${environment.saleApiBaseUrl}/contracts/${ID}/document`
		);
	}

	deleteContractDocument(ID: number): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts/${ID}/document`
		);
	}

	getContractSOW(ID: number): Observable<ResponsePayloadDTO<string>> {
		return this.httpClient.get<ResponsePayloadDTO<string>>(
			`${environment.saleApiBaseUrl}/contracts/contract-sow/${ID}`
		);
	}

	updateContractSOW(contractSOW: any): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.put<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts/contract-sow`,
			contractSOW
		);
	}

	getContractTerms(ID: number): Observable<ResponsePayloadDTO<string>> {
		return this.httpClient.get<ResponsePayloadDTO<string>>(
			`${environment.saleApiBaseUrl}/contracts/contract-terms/${ID}`
		);
	}

	updateContractTerms(
		contractTerms: any
	): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.put<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts/contract-terms`,
			contractTerms
		);
	}

	updateContractStage(
		contractStageUpdateDetails: ContractStageUpdateDetail
	): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.put<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts/contract-stage`,
			contractStageUpdateDetails
		);
	}

	deleteContract(contractID: number): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts/contract/${contractID}`
		);
	}
	getAssociatedClients(dealId: number): Observable<ResponsePayloadDTO<AssociatedEntities>> {
		return this.httpClient.get<ResponsePayloadDTO<AssociatedEntities>>(
			`${environment.saleApiBaseUrl}/contracts/${dealId}/associated-clients`
		);
	}

	updateAssociatedClients(
		associatedEntities: UpdateAssociatedEntities
	): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.put<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts/associated-clients`,
			associatedEntities
		);
	}

	unarchiveContractVersionByContractID(
		contractID: number
	): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.put<ResponsePayloadDTO<boolean>>(
			`${environment.saleApiBaseUrl}/contracts/unarchive-by-id/${contractID}`,
			contractID
		);
	}
	getDocumentDownloadUrl(id: number): string {
		return `${environment.utilityApiBaseUrl}/document/proposal/${id}`;
	}
}
