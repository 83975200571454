<div class="secondary-sidebar">

  <d1-loading-handler [state]="state">

    <ng-container *ngIf="state === LoadingState.loaded">

      <app-sidebar-option (click)="navigateToReport(reportOption)" *ngFor="let reportOption of reportOptions">
        {{ reportOption.reportTypeName }}
      </app-sidebar-option>

    </ng-container>

    <app-sidebar-option routerLink="/reports/client-draft-invoice">
      Client Draft Invoices
    </app-sidebar-option>

    <app-sidebar-option routerLink="/reports/client-invoice">
      Client Invoices
    </app-sidebar-option>

  </d1-loading-handler>

</div>
