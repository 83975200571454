<ng-container #scrollContainer></ng-container>
<div class="loading-wrapper">
  <mat-progress-spinner *ngIf="!locked" [diameter]="35" mode="indeterminate"></mat-progress-spinner>
</div>

<div *ngIf="locked && items?.length === 0">

  No items found

</div>
