<div (@dropdownReveal.done)="animationCompleted()" (@dropdownReveal.start)="animationStarted()"
     [@dropdownReveal]="state" [ngClass]="{'removed': removed}"
     class="wrapper">

  <div class="header">

    {{ title }}

  </div>

  <div class="action-wrapper">

    <ng-content></ng-content>

  </div>

</div>
