import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldflowDialogComponent} from "./fieldflow-dialog.component";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [
    FieldflowDialogComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule
  ],
  exports: [
    FieldflowDialogComponent
  ]
})
export class FieldFlowDialogModule {
}
