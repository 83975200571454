import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {SidebarMenuOption} from "./models/sidebar-menu-option";
import {Subject} from "rxjs";

@Component({
  selector: 'fieldflow-sidebar',
  templateUrl: './field-flow-sidebar.component.html',
  styleUrls: ['./field-flow-sidebar.component.css']
})
export class FieldFlowSidebarComponent implements OnInit, AfterViewInit {

  @Input() sidebarOptions: SidebarMenuOption[];

  @ViewChild('sidebarContainer', {read: ViewContainerRef}) sidebar: ViewContainerRef;

  @ViewChildren('option') options: QueryList<ElementRef>;

  optionHoverChanged$ = new Subject<SidebarMenuOption | null>();

  constructor() {
  }

  ngAfterViewInit(): void {
    this.registerHoverListeners();
  }

  ngOnInit(): void {
    this.optionHoverChanged$.subscribe((option) => {
      this.handleSubMenu(option);
    });
  }

  private registerHoverListeners(): void {
    this.options.forEach((option, index) => {
      option.nativeElement.addEventListener('mouseenter', () => {
        this.optionHoverChanged$.next(this.sidebarOptions[index]);
      });
    });
  }

  private handleSubMenu(option: SidebarMenuOption | null): void {
    if (option?.subMenu) {
      this.sidebar.clear();

      this.sidebar.createComponent(option.subMenu);
    }
  }

}
