import {Component} from '@angular/core';
import {MsalService} from '@azure/msal-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  constructor(private msalService: MsalService) {
  }

  login() {
    this.msalService.loginRedirect().subscribe();
  }
}
