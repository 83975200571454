<div class="header">

  <img alt="Logo" src="assets/images/d1_logo.svg">

</div>

<div class="body">

  <router-outlet></router-outlet>

</div>
