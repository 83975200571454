<div class="title">

  <div class="title-content">
    <mat-icon *ngIf="icon" fontSet="material-symbols-outlined">{{ icon }}</mat-icon>

    <h3> {{ title }}</h3>

    <div *ngIf="showIndicator" [ngStyle]="{'background-color': indicatorColor}" class="invalid-indicator"></div>
  </div>

  <div class="action-wrapper">
    <ng-content select="fieldflow-button"></ng-content>
  </div>

</div>

<p *ngIf="description" class="description">
  {{ description }}
</p>

<div [ngClass]="cardPerElement ? 'card-per-element' : 'content'">
  <ng-content></ng-content>
</div>
