import {HttpParams} from "@angular/common/http";

export class TableFilters {
    sort?: string = "id";
    search?: string = '';
    page?: number = 1;
    pageSize?: number = 10;
    isAscending?: boolean = false;
    additionalQueryParams?: HttpParams | null = null;

    constructor(initialValues?: Partial<TableFilters>) {
        Object.assign(this, initialValues);
    }
}
