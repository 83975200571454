import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, Subject } from "rxjs";
import { Filters } from "src/app/models/common/common";
import { NotesRequest } from "src/app/models/common/notes";
import { ResponsePayloadDTO } from 'src/app/models/salesAPIResponse';
import {
	Vendor,
	VendorContract,
	VendorDocumentRequest,
	VendorFiltered,
	VendorRates,
	VendorTypeViewModel,
} from "src/app/models/vendor/vendor";
import {TableFilters} from "src/app/shared/common/tableFilters";
import {environment} from "src/environments/environment";
import { CompetencyApplication, CompetencyTypesModel, VendorCompetencies } from "./models/competency-types.model";
import { List } from "carbon-components-angular";

@Injectable({
	providedIn: "root",
})
export class VendorService {
	private vendor: Subject<Vendor> = new BehaviorSubject<Vendor>({});

	constructor(private httpClient: HttpClient) {
	}

	getVendors(
		pageNumber: number,
		name: string,
		country: string,
		city: string,
		orderby: string,
		sort: string,
		isShowArchived: boolean
	): Observable<VendorFiltered[]> {
		const filters = {
			pageNumber: pageNumber,
			name: name?.toString(),
			country: country?.toString(),
			city: city?.toString(),
			orderby: orderby?.toString(),
			sort: sort?.toString(),
			isShowArchivedVendor: isShowArchived
		} as Filters;

		const params = new HttpParams().set("filters", JSON.stringify(filters));

		return this.httpClient.get<VendorFiltered[]>(
			`${environment.vendorApiBaseUrl}/vendor/getallfiltered`,
			{ params }
		);
	}

	getVendorById(id: number): Observable<any> {
		return this.httpClient.get<Vendor>(
			`${environment.vendorApiBaseUrl}/vendor/${id}`
		);
	}

	getVendorByIds(ids: number[]): Observable<Vendor[]> {
		let params = new HttpParams();

		if (ids.length > 1) {
			params = params.set("additionalIds", ids.slice(1, ids.length).toString());

			return this.httpClient.get<Vendor[]>(
				`${environment.vendorApiBaseUrl}/vendor/${ids[0]}`,
				{ params }
			);
		}

		// Handle single id
		return this.httpClient.get<Vendor>(
			`${environment.vendorApiBaseUrl}/vendor/${ids[0]}`,
			{ params }
		).pipe(
			map<Vendor, Vendor[]>(x => {
				return [x];
			})
		);
	}

	createVendor(vendor: Vendor): Observable<any> {
		return this.httpClient.post<any>(`${environment.vendorApiBaseUrl}/vendor/create`, vendor);
	}

	updateVendor(vendor: Vendor): Observable<boolean> {
		return this.httpClient.put<boolean>(`${environment.vendorApiBaseUrl}/vendor`, vendor);
	}

	getVendorType() {
		return this.httpClient.get<VendorTypeViewModel[]>(`${environment.vendorApiBaseUrl}/vendor/types`);
	}

	// Add Vendor Note operational Method start

	getPagedTechNotes(payload: TableFilters, vendorId: number): Observable<any> {
		return this.httpClient.get<any>(`${environment.vendorApiBaseUrl}/vendor/${vendorId}/notes?sort=${payload.sort}&search=${payload.search}&page=${payload.page}&pageSize=${payload.pageSize}&isAscending=${payload.isAscending}`);
	}

	getVendorNoteById(noteId: number): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.vendorApiBaseUrl}/vendor/notes/${noteId}`
		);
	}

	updateVendorNote(vendorNote: NotesRequest, vendorId: number): Observable<boolean> {
		return this.httpClient.put<boolean>(`${environment.vendorApiBaseUrl}/vendor/${vendorId}/notes`, vendorNote);
	}

	createVendorNote(vendorNote: NotesRequest, vendorId: number): Observable<number> {
		return this.httpClient.post<number>(`${environment.vendorApiBaseUrl}/vendor/${vendorId}/notes`, vendorNote);
	}

	deleteVendorNote(noteId: number): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${environment.vendorApiBaseUrl}/vendor/notes/${noteId}`);
	}

	// Add Vendor Note operational Method End

	// Add Vendor Documents operational Method Start
	getDocumentsByVendorId(vendorId: number): Observable<VendorDocumentRequest[]> {
		return this.httpClient.get<VendorDocumentRequest[]>(`${environment.vendorApiBaseUrl}/vendor/${vendorId}/documents`);
	}

	getDocumentByDocumentId(documentId: number): Observable<VendorDocumentRequest> {
		return this.httpClient.get<VendorDocumentRequest>(`${environment.vendorApiBaseUrl}/vendor/documents/${documentId}`);
	}

	createDocument(document: VendorDocumentRequest, vendorId: number): Observable<number> {
		return this.httpClient.post<number>(`${environment.vendorApiBaseUrl}/vendor/${vendorId}/documents`, document);
	}

	updateDocument(document: VendorDocumentRequest, vendorId: number): Observable<number> {
		return this.httpClient.put<number>(`${environment.vendorApiBaseUrl}/vendor/${vendorId}/documents`, document);
	}

	deleteDocumentById(documentId: number): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${environment.vendorApiBaseUrl}/vendor/documents/${documentId}`);
	}

	// Add Vendor Documents operational Method End


	//  These mothods are being used for passing data to child Component.
	getVendorFieldServiceInfo() {
		return this.vendor.asObservable();
	}

	addVendorFieldServiceInfo(data: Vendor) {
		this.vendor.next(data);
	}

	archiveOrUnacrhiveVendor(vendors: any): Observable<any> {
		return this.httpClient.put<any>(`${environment.vendorApiBaseUrl}/vendor/archivesOrUnarchive`, vendors);
	}

	//Add Vendor rates Operational Method start
	//Create Vendor
	createVendorRates(
		VendorRates: VendorRates,
		vendorId: number
	): Observable<any> {
		return this.httpClient.post<number>(
			`${environment.vendorApiBaseUrl}/vendor/${vendorId}/VendorRates`,
			VendorRates
		);
	}

	//Get vendor rates
	getVendorRates(vendorId: number): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.vendorApiBaseUrl}/vendor/${vendorId}/VendorRates`
		);
	}

	//Get vendor rates by rateId
	getVendorRateById(rateId: number): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.vendorApiBaseUrl}/vendor/VendorRates/${rateId}`
		);
	}

	updateVendorRates(
		VendorRates: VendorRates,
		rateId: number
	): Observable<boolean> {
		return this.httpClient.put<boolean>(
			`${environment.vendorApiBaseUrl}/vendor/${rateId}/VendorRates`,
			VendorRates
		);
	}

	deleteVendorRate(rateId: number): Observable<boolean> {
		return this.httpClient.delete<boolean>(
			`${environment.vendorApiBaseUrl}/vendor/VendorRates/${rateId}`
		);
	}

	deleteVendorById(id: number): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.vendorApiBaseUrl}/vendor/delete/${id}`
		);
	}

	getAllVendors(): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.vendorApiBaseUrl}/vendor/getall`
		);
	}

	getVendorTechs(vendorID: number): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.vendorApiBaseUrl}/vendor/${vendorID}/getVendorTech`
		);
	}

	getAllContractsAndTicketsByVendorId(vendorId: number): Observable<ResponsePayloadDTO<VendorContract[]>> {
		return this.httpClient.get<ResponsePayloadDTO<VendorContract[]>>(`${environment.workOrderApiBaseUrl}/tickets/vendor-tickets/${vendorId}`);
	}

	getDocumentDownloadUrl(id: number): string {
		return `${environment.utilityApiBaseUrl}/document/vendor/${id}`;
	}

	getCompetenciesTypes(): Observable<CompetencyTypesModel[]> {
		return this.httpClient.get<CompetencyTypesModel[]>(`${environment.vendorApiBaseUrl}/vendor/competencyWithTypes`);
	}

	addVendorCompetencies(vendorCompetencies: VendorCompetencies) {
		return this.httpClient.post<any>(`${environment.vendorApiBaseUrl}/vendor/addVendorCompetencies`, vendorCompetencies);
	}

	getVendorCompetencies(vendorId: number) {
		return this.httpClient.get<any>(`${environment.vendorApiBaseUrl}/vendor/vendorCompetenciesByVendorId/${vendorId}`);
	}

  deleteVendorCompetencies(vendorId: number, competencyId: number) {
    return this.httpClient.delete<any>(`${environment.vendorApiBaseUrl}/vendor/deleteVendorCompetencies/${vendorId}/${competencyId}`);
  }

  getVendorTechCompetencyApplication(vendorId: number, techId: number | null): Observable<CompetencyApplication[]> {
    if (techId === null) {
      return this.httpClient.get<any>(`${environment.vendorApiBaseUrl}/vendor/vendorTechCompetencyApplication/${vendorId}`);
    }
    return this.httpClient.get<any>(`${environment.vendorApiBaseUrl}/vendor/vendorTechCompetencyApplication/${vendorId}/${techId}`);
  }

  createVendorTechCompetencyApplication(competencyApplication: CompetencyApplication) {
    return this.httpClient.post<any>(`${environment.vendorApiBaseUrl}/vendor/createVendorTechCompetencyApplication`, competencyApplication);
  }

  updateVendorTechCompetencyApplication(competencyApplication: CompetencyApplication) {
    return this.httpClient.put<any>(`${environment.vendorApiBaseUrl}/vendor/updateVendorTechCompetencyApplication`, competencyApplication);
	}

	checkNameSimilarity(vendorName: string): Observable<any> {
		const encodedVendorName = encodeURIComponent(vendorName);
		return this.httpClient.get<Vendor>(
			`${environment.vendorApiBaseUrl}/vendor/nameSimilarity/${encodedVendorName}`
		);
	}
}
