import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldFlowBannerComponent} from './field-flow-banner.component';
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    FieldFlowBannerComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    FieldFlowBannerComponent
  ]
})
export class FieldFlowBannerModule {
}
