import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldFlowSectionCardComponent} from './field-flow-section-card.component';
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    FieldFlowSectionCardComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    FieldFlowSectionCardComponent
  ]
})
export class FieldFlowSectionCardModule {
}
