export interface UserApplicationInformation {
  enabledApplicationIds: number[],
  vendorPortalApplicationInformation?: VendorPortalApplicationInformation | null,
  clientPortalApplicationInformation?: ClientPortalApplicationInformation | null,
}

export interface VendorPortalApplicationInformation {
  vendorId: number,
  technicianId: number,
  admin: boolean,
}

export interface ClientPortalApplicationInformation {
  clientId: number,
  admin: boolean,
}

export enum ApplicationTypes {
  core = 1,
  vendorPortal = 2,
  clientPortal = 3,
}

