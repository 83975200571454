import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.css']
})
export class SuggestionComponent implements OnInit {

  constructor() {
  }

  @Input() suggestion: string;
  @Output() clicked = new EventEmitter<string>();

  @HostListener('click')
  click() {
    this.clicked.emit(this.suggestion);
  }

  ngOnInit(): void {
  }

}
