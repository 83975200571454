import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AgreementTemplate } from 'src/app/models/agreement-template/agreement-template';
import { ResponsePayloadDTO } from 'src/app/models/salesAPIResponse';
import { Competency, CompetencyRequest, CompetencyType } from 'src/app/models/vendor/vendor';
import { TableFilters } from 'src/app/shared/common/tableFilters';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompetencyService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getPagedCompetencies(payload: TableFilters): Observable<any> {
    return this.httpClient.get<any>(`${environment.configurationsApiBaseUrl}/competencies?sort=${payload.sort}&search=${payload.search}&page=${payload.page}&pageSize=${payload.pageSize}&isAscending=${payload.isAscending}`);
  }

  getCompetencyById(id: number): Observable<ResponsePayloadDTO<Competency>> {
    return this.httpClient.get<ResponsePayloadDTO<Competency>>(`${environment.configurationsApiBaseUrl}/competencies/${id}`);
  }

  saveCompetency(template: CompetencyRequest): Observable<ResponsePayloadDTO<number>> {
    return this.httpClient.post<ResponsePayloadDTO<number>>(`${environment.configurationsApiBaseUrl}/competencies`, template);
  }

  updateCompetency(template: CompetencyRequest): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.put<ResponsePayloadDTO<boolean>>(`${environment.configurationsApiBaseUrl}/competencies`, template);
  }

  deleteCompetency(id: number): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.delete<ResponsePayloadDTO<boolean>>(`${environment.configurationsApiBaseUrl}/competencies/${id}`);
  }

  getAllTypes(): Observable<ResponsePayloadDTO<CompetencyType[]>> {
    return this.httpClient.get<ResponsePayloadDTO<CompetencyType[]>>(`${environment.configurationsApiBaseUrl}/competencies/types`);
  }
}
