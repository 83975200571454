import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfiniteScrollContainerComponent} from './infinite-scroll-container.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@NgModule({
  declarations: [
    InfiniteScrollContainerComponent
  ],
  exports: [
    InfiniteScrollContainerComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class InfiniteScrollContainerModule {
}

