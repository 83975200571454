<button>

  <div class="icon">
    <mat-icon fontSet="material-symbols-outlined">search</mat-icon>
  </div>

  <div class="details">
    <h4>{{ suggestion }}</h4>
  </div>

  <mat-icon fontSet="material-symbols-outlined">arrow_forward</mat-icon>

</button>
