import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map, Observable} from "rxjs";
import {GetListWorkflowModel} from "./models/get-list-workflow.model";
import {GetWorkflowModel} from "./models/get-workflow/get-workflow.model";
import {GetWorkflowRevisionModel} from "./models/get-workflow/get-workflow-revision/get-workflow-revision.model";
import {
  CreateWorkflowCatalogMilestoneModel
} from "./models/create-workflow-catalog-milestone/create-workflow-catalog-milestone.model";
import {CreateWorkflowModel} from "./create-workflow.model";
import {UpdateWorkflowModel} from "./models/update-workflow.model";
import {Levels} from "../milestone/models/levels.enum";
import {Stages} from "../milestone/models/stages.enum";
import {ResourceHierarchy} from "../milestone/models/resource-hierarchy.model";
import {IStatusOverviewModel} from "../status/models/status-overview.model";

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  constructor(private http: HttpClient) {
  }

  createWorkflow(workflow: CreateWorkflowModel): Observable<GetListWorkflowModel> {
    return this.http.post<GetListWorkflowModel>(`${environment.milestoneApiBaseUrl}/workflow`, workflow);
  }

  updateWorkflow(workflowId: number, workflow: UpdateWorkflowModel): Observable<GetWorkflowModel> {
    return this.http.put<GetWorkflowModel>(`${environment.milestoneApiBaseUrl}/workflow/${workflowId}`, workflow);
  }

  getWorkflows(page: number, pageSize: number): Observable<{ count: number, results: GetListWorkflowModel[] }> {
    return this.http.get<{
      count: number,
      results: GetListWorkflowModel[]
    }>(`${environment.milestoneApiBaseUrl}/workflow`, {
      params: {
        page: page.toString(),
        pageSize: pageSize.toString()
      }
    });
  }

  publishWorkflowRevision(workflowId: number, revisionId: number): Observable<void> {
    return this.http.put<void>(`${environment.milestoneApiBaseUrl}/workflow/${workflowId}/revisions/${revisionId}/publish`, null);
  }

  getWorkflowById(id: number): Observable<GetWorkflowModel> {
    return this.http.get<GetWorkflowModel>(`${environment.milestoneApiBaseUrl}/workflow/${id}`).pipe(
      map((workflow: GetWorkflowModel) => {
        return {
          ...workflow,
          revisions: workflow.revisions.map(revision => {
            return {
              ...revision,
              createdAt: new Date(revision.createdAt)
            }
          })
        }
      })
    );
  }

  createRevision(workflowId: number, catalogMilestones: CreateWorkflowCatalogMilestoneModel[]): Observable<GetWorkflowRevisionModel> {
    return this.http.post<GetWorkflowRevisionModel>(`${environment.milestoneApiBaseUrl}/workflow/${workflowId}/revisions`, catalogMilestones).pipe(
      map((revision: GetWorkflowRevisionModel) => {
        return {
          ...revision,
          createdAt: new Date(revision.createdAt)
        }
      })
    );
  }

  getPublishedWorkflows(level: Levels, page: number, pageSize: number): Observable<{
    count: number,
    results: GetListWorkflowModel[]
  }> {
    return this.http.get<{
      count: number,
      results: GetListWorkflowModel[]
    }>(`${environment.milestoneApiBaseUrl}/workflow/published/${level}`, {
      params: {
        page: page.toString(),
        pageSize: pageSize.toString()
      }
    });
  }

  associateWorkflowsWithResource(stage: Stages, resource: ResourceHierarchy, workflowIds: number[]): Observable<IStatusOverviewModel[]> {
    return this.http.post<IStatusOverviewModel[]>(`${environment.milestoneApiBaseUrl}/workflow/associate`, {
      stage,
      resourceHierarchy: resource,
      workflowIds
    });
  }
}
