import { HttpClient,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PowerBIReportInformationDTO } from 'src/app/models/reports/reports';
import { environment } from 'src/environments/environment';
import { StringDecoder } from 'string_decoder';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(private httpClient: HttpClient) { }

    getDocumentDefinitionTypes(): Observable<any> {
        return this.httpClient.get<any>(`${environment.configurationsApiBaseUrl}/document-definitions`);
    }

    getPaymentTerms(): Observable<any> {
        return this.httpClient.get<any>(`${environment.configurationsApiBaseUrl}/common/paymentTerms`);
    }

    getPaymentTypes(): Observable<any> {
        return this.httpClient.get<any>(`${environment.configurationsApiBaseUrl}/common/paymentTypes`);
    }

    getPowerBIReportConfig(reportId: string, workSpaceId: any, clientId?: string): Observable<any> {
        const url = `${environment.utilityApiBaseUrl}/report/${workSpaceId}/${reportId}`;
        let params = new HttpParams();
        if (clientId) {
          params = params.append('clientId', clientId);
        }
        return this.httpClient.get<any>(url, { params });
      }
      getPowerBIReportInformation(): Observable<PowerBIReportInformationDTO[]> {
        const url = `${environment.utilityApiBaseUrl}/reports/all`;
        return this.httpClient.get<PowerBIReportInformationDTO[]>(url);
      }

	  downloadDocument(url: string, fileName: string): Promise<Blob> {
		return new Promise((resolve, reject) => {
			const jwtToken = JSON.parse(localStorage.getItem('jwtToken') as any);
			let documentName = fileName;

			if (documentName.indexOf(".") > 0) {
				documentName = documentName.substring(0, documentName.lastIndexOf("."));
			}

			const req = new XMLHttpRequest();
			req.open("GET", url, true);
			req.setRequestHeader("Authorization", "Bearer " + jwtToken);
			req.setRequestHeader("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);
			req.responseType = "blob";

			req.onload = function(event) {
				if (req.status >= 200 && req.status < 300) {
					const blob = req.response;
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = documentName;
					link.click();
					resolve(blob);
				} else {
					reject(new Error(`Failed to download document. Status: ${req.status}`));
				}
			};

			req.onerror = function() {
				reject(new Error('Network error'));
			};

			req.send();
		});
	}

}
