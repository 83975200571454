import {Injectable} from "@angular/core";
import {Action, State, StateContext} from "@ngxs/store";
import {DeliverySearchStateModel, SearchCategory} from "./models/delivery-search.state-model";
import {Client} from "../../models/client/client";
import {Contract} from "../../models/proposals/proposal";
import {Ticket} from "../../models/tickets/tickets";
import {
  DeliverySearchCategoryEnum
} from "../../core/layout/components/delivery-search/components/scroll-item/enums/delivery-search-category.enum";
import {
  InfiniteScrollItemModel
} from "../../shared/components/infinite-scroll-container/models/infinite-scroll-item.model";

export class UpdateDeliverySearchClient {
  static readonly type = '[Delivery Search] Update Clients';

  constructor(public payload: Partial<SearchCategory<Client>>) {
  }
}

export class UpdateDeliverySearchContract {
  static readonly type = '[Delivery Search] Update Contracts';

  constructor(public payload: Partial<SearchCategory<Contract>>) {
  }
}

export class UpdateDeliverySearchTicket {
  static readonly type = '[Delivery Search] Update Tickets';

  constructor(public payload: Partial<SearchCategory<Ticket>>) {
  }
}

export class PumpDeliverySearchSelection {
  static readonly type = '[Delivery Search] Pump State';

  constructor(public items: Map<DeliverySearchCategoryEnum, InfiniteScrollItemModel<any>>) {
  }
}

export class ResetDeliverySearch {
  static readonly type = '[Delivery Search] Reset State';
}

/**
 * Used to persist the state of the delivery search component.
 */
@State<DeliverySearchStateModel>({
  defaults: {
    clients: null,
    contracts: null,
    tickets: null
  },
  name: 'deliverySearch'
})
@Injectable()
export class DeliverySearchState {

  public static readonly StateKeyCategories = new Map<DeliverySearchCategoryEnum, string>([
    [DeliverySearchCategoryEnum.client, 'clients'],
    [DeliverySearchCategoryEnum.contract, 'contracts'],
    [DeliverySearchCategoryEnum.ticket, 'tickets']
  ]);

  @Action(UpdateDeliverySearchClient)
  updateDeliverySearchClient(ctx: StateContext<DeliverySearchStateModel>, action: UpdateDeliverySearchClient) {
    const currentState = ctx.getState();
    let newClients = this.coalesceCategory(currentState.clients, action.payload);

    if (currentState.clients?.activeRecordGuid !== newClients.activeRecordGuid) {
      ctx.patchState({
        contracts: null,
        tickets: null
      });
    }

    if (newClients.items?.find(x => x.guid === newClients.activeRecordGuid) === undefined) {
      ctx.patchState({
        contracts: null,
        tickets: null
      });

      newClients = {
        ...newClients,
        activeRecordGuid: null
      };
    }

    ctx.patchState({
      clients: newClients
    });
  }

  @Action(UpdateDeliverySearchContract)
  updateDeliverySearchContract(ctx: StateContext<DeliverySearchStateModel>, action: UpdateDeliverySearchContract) {
    const currentState = ctx.getState();
    let newContracts = this.coalesceCategory(currentState.contracts, action.payload);

    if (currentState.contracts?.activeRecordGuid !== newContracts.activeRecordGuid) {
      ctx.patchState({
        tickets: null
      });
    }

    if (newContracts.items?.find(x => x.guid === newContracts.activeRecordGuid) === undefined) {
      ctx.patchState({
        tickets: null
      });

      newContracts = {
        ...newContracts,
        activeRecordGuid: null
      };
    }

    ctx.patchState({
      contracts: newContracts
    });
  }

  @Action(UpdateDeliverySearchTicket)
  updateDeliverySearchTicket(ctx: StateContext<DeliverySearchStateModel>, action: UpdateDeliverySearchTicket) {
    const currentState = ctx.getState();
    ctx.patchState({
      tickets: this.coalesceCategory(currentState.tickets, action.payload)
    });
  }

  @Action(PumpDeliverySearchSelection)
  pumpDeliverySearchCategory(ctx: StateContext<DeliverySearchStateModel>, action: PumpDeliverySearchSelection) {
    let currentState = ctx.getState();

    // Update the state, adding new items if required.
    // Additionally, set the active record guid to the provided value.
    action.items.forEach((value, key) => {
      const stateKey = DeliverySearchState.StateKeyCategories.get(key)!;
      let currentCategory: SearchCategory<any> = currentState[stateKey];
      if (!currentCategory) {
        currentCategory = {
          query: null,
          items: null,
          totalRecords: null,
          activeRecordGuid: null
        };
      }

      const existingItem = currentCategory.items?.find(x => x.guid === value.guid);

      currentCategory.activeRecordGuid = value.guid;
      currentCategory.items = existingItem ? currentCategory.items : [value, ...(currentCategory.items ?? [])];

      currentState = {
        ...currentState,
        [stateKey]: currentCategory
      };
    });

    // Reset any selections for categories that were not provided.
    currentState = {
      ...currentState,
      clients: action.items.has(DeliverySearchCategoryEnum.client) ? currentState.clients : this.coalesceCategory(currentState.clients, {activeRecordGuid: null}),
      contracts: action.items.has(DeliverySearchCategoryEnum.contract) ? currentState.contracts : this.coalesceCategory(currentState.contracts, {activeRecordGuid: null}),
      tickets: action.items.has(DeliverySearchCategoryEnum.ticket) ? currentState.tickets : this.coalesceCategory(currentState.tickets, {activeRecordGuid: null})
    };

    ctx.patchState(currentState);
  }

  @Action(ResetDeliverySearch)
  resetDeliverySearch(ctx: StateContext<DeliverySearchStateModel>) {
    ctx.setState({
      clients: null,
      contracts: null,
      tickets: null
    });
  }

  private coalesceCategory<T>(current: SearchCategory<T> | null, partial: Partial<SearchCategory<T>>): SearchCategory<T> {
    return {
      query: this.coalesceProperty(partial.query, current?.query),
      items: this.coalesceProperty(partial.items, current?.items),
      totalRecords: this.coalesceProperty(partial.totalRecords, current?.totalRecords),
      activeRecordGuid: this.coalesceProperty(partial.activeRecordGuid, current?.activeRecordGuid)
    };
  }

  private coalesceProperty<T>(partialValue: T | null | undefined, currentValue: T | null | undefined): T | null {
    return partialValue === undefined ? (currentValue ?? null) : partialValue;
  }

}
