<div class="child-header ">
  <div class="bx--col-sm-16 bx--col-md-8">
    <h1 class="custom-h1" style="text-align: center;">UNAUTHORIZED</h1>
  </div>
</div>
<div class="bx--grid py-3 bx--grid--condensed">
  <div class="bx--row bx--grid--narrow">
    <div class="bx--col-sm-16 bx--col-md-12 bx--col-lg-8">
    </div>
  </div>
</div>
<div style="text-align: center;">
  <img alt="" src="../../../assets/images/unauthorize.png">
  <h1>Please check with the site admin if you believe this is a mistake.</h1>

</div>
