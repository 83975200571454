import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {IUpdateResourceTaskModel} from "./models/update-resource-task.model";
import {ResourceHierarchy} from "../milestone/models/resource-hierarchy.model";
import {GetResourceTask} from "./models/get-resource-task";

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  constructor(private http: HttpClient) {
  }

  public fail(taskId: string, hierarchy: ResourceHierarchy): Observable<GetResourceTask> {
    return this.http.post<GetResourceTask>(`${environment.milestoneApiBaseUrl}/task/${taskId}/fail`, hierarchy);
  }

  public complete(taskId: string, hierarchy: ResourceHierarchy): Observable<GetResourceTask> {
    return this.http.post<GetResourceTask>(`${environment.milestoneApiBaseUrl}/task/${taskId}/complete`, hierarchy);
  }

  public updateResourceTask(id: string, task: IUpdateResourceTaskModel): Observable<void> {
    return this.http.patch<void>(`${environment.milestoneApiBaseUrl}/task/resource/${id}`, task);
  }
}
