import {HttpClient, HttpParams} from '@angular/common/http';

import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ClientContractsGetDto} from 'src/app/models/project/project';
import {ContractActivityResourceMainDto} from 'src/app/models/resources/resource';

import {ResponsePayloadDTO} from 'src/app/models/salesAPIResponse';

import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectActivityResourceService {
  private tech: Subject<ClientContractsGetDto> = new BehaviorSubject<ClientContractsGetDto>({});

  constructor(private httpClient: HttpClient) {
  }

  getResourceCategories(): Observable<ResponsePayloadDTO<any[]>> {
    return this.httpClient.get<ResponsePayloadDTO<any[]>>(
      `${environment.configurationsApiBaseUrl}/resource-categories`
    );
  }

  getResourcesByCategoryId(categoryName: any): Observable<ResponsePayloadDTO<any[]>> {
    return this.httpClient.get<ResponsePayloadDTO<any[]>>(
      `${environment.configurationsApiBaseUrl}/resources/category/${categoryName}`
    );
  }

  addActivityResource(
    contractActivityResources: ContractActivityResourceMainDto
  ): Observable<ResponsePayloadDTO<number>> {
    return this.httpClient.post<ResponsePayloadDTO<number>>(
      `${environment.projectApiBaseUrl}/contract-activity-resources`,
      contractActivityResources
    );
  }

  updateProjectActivityresources(contractActivityResources: ContractActivityResourceMainDto): Observable<ResponsePayloadDTO<number>> {
    return this.httpClient.put<ResponsePayloadDTO<number>>(
      `${environment.projectApiBaseUrl}/contract-activity-resources`,
      contractActivityResources
    );
  }

  deleteResourceByResourceId(
    id: number
  ): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
      `${environment.projectApiBaseUrl}/contract-activity-resources/${id}`
    );
  }

  getProjectActivityResourcesByActivityId(
    activityId: number
  ): Observable<ResponsePayloadDTO<any>> {
    return this.httpClient.get<ResponsePayloadDTO<any>>(
      `${environment.projectApiBaseUrl}/contract-activities/${activityId}/resources`
    );
  }

  getClientContractsByClientId(clientId: number, ContractClosed: boolean, page?: number, pageSize?: number): Observable<ResponsePayloadDTO<ClientContractsGetDto[]>> {
    let params = new HttpParams();
    if (page) {
      params = params.append('pageNumber', page.toString());
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize.toString());
    }

    return this.httpClient.get<ResponsePayloadDTO<ClientContractsGetDto[]>>(`${environment.projectApiBaseUrl}/contract/${clientId}/contracts?showClosedContract=${ContractClosed}`, {
      params
    });
  }

  getContractByContractId(id: number): Observable<ResponsePayloadDTO<ClientContractsGetDto>> {
    return this.httpClient.get<ResponsePayloadDTO<ClientContractsGetDto>>(`${environment.projectApiBaseUrl}/contract/contracts/${id}`);
  }

  getContractServiceInfo() {
    return this.tech.asObservable();
  }

  shareContractInfo(data: ClientContractsGetDto) {
    this.tech.next(data);
  }
}
