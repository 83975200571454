import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UserAccountInfo} from 'src/app/models/user/user';
import {MsalService} from '@azure/msal-angular';
import {CookieService} from 'ngx-cookie-service';
import {UserApplicationInformation} from './models/user-application-information';
import {ActiveVendorUserModel} from "./models/active-vendor-user.model";
import {ResponsePayloadDTO} from "../../models/salesAPIResponse";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient, private msalService: MsalService,
              private cookies: CookieService) {
  }

  saveLocalUser(obj: UserAccountInfo): Observable<any> {
    return this.httpClient.post(`${environment.identityBaseUrl}/identity/users/SaveLocal`, obj);
  }

  getUserProfile(userId: string, b2cUser?: boolean): Observable<any> {
    const queryParams = new HttpParams().set('b2cUser', b2cUser ?? false);

    return this.httpClient.get(`${environment.identityBaseUrl}/identity/users/${userId}`, {
      params: queryParams
    });
  }

  getCurrentUserProfile(): Observable<any> {
    return this.httpClient.get(`${environment.identityBaseUrl}/identity/users/Me`)
  }

  getJwtToken(): Observable<any> {
    return this.httpClient.get(`${environment.identityBaseUrl}/identity/users/GenerateToken`);
  }

  getCurrentUserTeamIds(): Observable<ResponsePayloadDTO<number[]>> {
    return this.httpClient.get<ResponsePayloadDTO<number[]>>(`${environment.identityBaseUrl}/identity/users/Me/Teams`);
  }

  resetPassword(userId: string): Observable<any> {
    return this.httpClient.get(`${environment.identityBaseUrl}/identity/users/ResetPassword/${userId}`)
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.cookies.deleteAll('/');
    setTimeout(() => {
      this.msalService.logout();
    }, 200);
  }

  saveB2CUser(obj: any): Observable<any> {
    return this.httpClient.post(`${environment.identityBaseUrl}/identity/users/SaveB2C`, obj);
  }

  deleteUser(userId: string): Observable<any> {
    return this.httpClient.delete(`${environment.identityBaseUrl}/identity/users/${userId}`)
  }

  SaveApplicationsUsers(obj: any): Observable<any> {
    return this.httpClient.post(`${environment.identityBaseUrl}/identity/users/SaveApplicationsUser`, obj);
  }

  getUsers(): Observable<any> {
    return this.httpClient.get(`${environment.identityBaseUrl}/identity/users`);
  }

  getUserApplicationInformation(userId: string): Observable<UserApplicationInformation> {
    return this.httpClient.get(`${environment.identityBaseUrl}/identity/users/${userId}/application`).pipe(
      map<any, UserApplicationInformation>((value) => {
        return value.results;
      })
    );
  }

  updateUserApplicationInformation(userId: string, information: UserApplicationInformation): Observable<void> {
    return this.httpClient.put<void>(`${environment.identityBaseUrl}/identity/users/${userId}/application`, information);
  }

  getActiveVendorUsers(technicianIds: number[]): Observable<ActiveVendorUserModel[]> {
    return this.httpClient.post<any>(`${environment.identityBaseUrl}/identity/users/vendorUsers/active`, technicianIds).pipe(
      map<any, ActiveVendorUserModel[]>((value) => {
        return value.results;
      })
    );
  }
}
