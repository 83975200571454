import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldFlowButtonComponent} from './field-flow-button.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@NgModule({
  declarations: [
    FieldFlowButtonComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    FieldFlowButtonComponent
  ]
})
export class FieldFlowButtonModule {
}
