<div class="sidebar-wrapper">

  <div class="sidebar">

    <button #option *ngFor="let option of sidebarOptions" [routerLink]="option.route" class="option">

      <mat-icon fontSet="material-symbols-outlined">{{ option.icon }}</mat-icon>

      <div class="option-expanded">

        <div>
          {{ option.title }}
        </div>

        <mat-icon fontSet="material-symbols-outlined">chevron_right</mat-icon>

      </div>

    </button>

  </div>

  <div class="secondary-sidebar-wrapper">

    <ng-container #sidebarContainer></ng-container>

  </div>

</div>

<div class="blackout-pane"></div>
