<ng-container [ngSwitch]="state">

  <ng-container *ngSwitchCase="loadingStates.loaded">
    <ng-content></ng-content>
  </ng-container>

  <div *ngSwitchCase="loadingStates.loading" class="loading-wrapper">

    <mat-spinner [diameter]="35"></mat-spinner>

  </div>

  <div *ngSwitchCase="loadingStates.invalid" class="error">

    <mat-icon fontSet="material-symbols-outlined">warning</mat-icon>

    <h2>
      The Requested Resource Could Not Be Found
    </h2>

    <p>
      The requested resource could not be found. Please check the URL and try again.
    </p>

  </div>

  <div *ngSwitchDefault class="error">

    <mat-icon fontSet="material-symbols-outlined">warning</mat-icon>

    <h2>
      An error has occurred
    </h2>

    <p>
      Something went wrong while trying to load the content. Please try again later.
    </p>

  </div>

</ng-container>
