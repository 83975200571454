import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {InfiniteScrollConfigurationModel} from "./models/infinite-scroll-configuration.model";
import {InfiniteScrollItemModel} from "./models/infinite-scroll-item.model";
import {INFINITE_SCROLL_DATA} from "./injection-tokens/infinite-scroll-data.injection-token";
import {
  BehaviorSubject,
  catchError,
  filter,
  mergeMap,
  pairwise,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError
} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {INFINITE_SCROLL_CONTEXT} from "./injection-tokens/infinite-scroll-context.injection-token";

@Component({
  selector: 'd1-infinite-scroll-container',
  templateUrl: './infinite-scroll-container.component.html',
  styleUrls: ['./infinite-scroll-container.component.css']
})
export class InfiniteScrollContainerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() configuration: InfiniteScrollConfigurationModel<unknown>;

  @ViewChild('scrollContainer', {read: ViewContainerRef}) scrollContainer: ViewContainerRef;

  @Input() query: any;

  @Input() initialValues?: { items: InfiniteScrollItemModel<unknown>[], totalRecords: number };

  @Output() change = new EventEmitter<{ items: InfiniteScrollItemModel<unknown>[], totalRecords: number }>();

  locked = false;
  items: InfiniteScrollItemModel<unknown>[] = [];

  private refs: ComponentRef<unknown>[] = [];
  private totalRecords: number | null = null;
  private page = 1;
  private observer: IntersectionObserver;

  private query$ = new BehaviorSubject<any>(null);
  private loadItems$ = new Subject<void>();

  private componentDestroyed$ = new Subject<void>();

  constructor(private toastrService: ToastrService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.query.firstChange && changes.query.currentValue !== changes.query.previousValue) {
      this.query$.next(this.query);
      this.loadItems$.next();
    }
  }

  ngOnDestroy(): void {
    this.observer.disconnect();

    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngAfterViewInit(): void {
    if (!this.initialValues) {
      this.loadItems$.next();
    } else {
      this.configureInitialValues();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnInit(): void {
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.loadItems$.next();
      }
    });

    this.query$.next(this.query);

    this.loadItems$.pipe(
      mergeMap(() => this.query$),
      startWith(this.query),
      pairwise(),
      tap(([last, current]) => {
        // Cleanup state prior to loading new items
        if (last !== null && current !== last) {
          this.locked = false;
          this.totalRecords = null;
          this.page = 1;
          this.items = [];
          this.refs.forEach(ref => ref.destroy());
          this.refs = [];
          this.scrollContainer.clear();
          this.observer.disconnect();
        }
      }),
      filter(() => !this.locked),
      switchMap(([_, currentQuery]) => this.configuration.loadCallback({
        page: this.page,
        pageSize: this.configuration.pageSize
      }, currentQuery)),
      takeUntil(this.componentDestroyed$),
      catchError((error) => {
        this.toastrService.error('An error occurred while loading items');
        return throwError(() => error);
      })
    ).subscribe((response) => {
      const uniqueRecords = response.records.filter(value => {
        return this.items.find(x => x.guid === value.guid) === undefined;
      });

      this.totalRecords = response.totalRecords;
      this.items = this.items.concat(uniqueRecords);

      this.change.emit({
        items: this.items,
        totalRecords: this.totalRecords
      });

      this.renderItems(uniqueRecords);

      this.page++;
    });
  }

  private configureInitialValues(): void {
    if (this.initialValues) {
      this.totalRecords = this.initialValues.totalRecords;
      this.items = this.initialValues.items;
      this.page = Math.ceil(this.items.length / this.configuration.pageSize) + 1;

      this.renderItems(this.items);
    }
  }

  private renderItems(items: InfiniteScrollItemModel<unknown>[]): void {
    this.observer.disconnect();

    items.forEach((item) => {
      const injector = Injector.create({
        providers: [
          {provide: INFINITE_SCROLL_DATA, useValue: item},
          {provide: INFINITE_SCROLL_CONTEXT, useValue: this.configuration.context},
        ],
      });

      const componentRef = this.scrollContainer.createComponent(this.configuration.itemComponent, {
        injector
      });
      this.refs.push(componentRef);
    });

    if (this.refs.length > 0) {
      this.observer.observe(this.refs[this.refs.length - 1].location.nativeElement);
    }

    if (this.totalRecords !== null && this.items.length >= this.totalRecords) {
      this.locked = true;

      this.observer.disconnect();
    }
  }

}
