<button>

  <div class="icon">
    <mat-icon fontSet="material-symbols-outlined">{{ isDeepLink ? 'plagiarism' : 'draft'}}</mat-icon>
  </div>

  <div class="details">
    <h4>{{ citation.title }}</h4>
    <p *ngIf="citation.contentSlug != null">{{ citation.contentSlug }}</p>
  </div>

  <mat-icon fontSet="material-symbols-outlined">arrow_forward</mat-icon>

</button>
