import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonService} from "../../../../services/common/common.service";
import {LoadingState} from "../../../../state/loading-state.enum";
import {catchError, Subject, takeUntil, throwError} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {PowerBIReportInformationDTO} from "../../../../models/reports/reports";
import {Router} from "@angular/router";

@Component({
  selector: 'app-reports-sidebar',
  templateUrl: './reports-sidebar.component.html',
  styleUrls: ['./reports-sidebar.component.css']
})
export class ReportsSidebarComponent implements OnInit, OnDestroy {

  componentDestroyed$ = new Subject<void>();
  state = LoadingState.loading;
  readonly LoadingState = LoadingState;

  reportOptions: PowerBIReportInformationDTO[] = [];

  constructor(private commonService: CommonService, private toastrService: ToastrService, private router: Router) {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    this.commonService.getPowerBIReportInformation().pipe(
      takeUntil(this.componentDestroyed$),
      catchError(error => {
        this.toastrService.error('Failed to load reports');

        return throwError(() => error);
      })
    ).subscribe(reports => {
      const reportOptions = new Map<number, PowerBIReportInformationDTO>();
      reports.forEach(report => {
        reportOptions.set(report.reportTypeId, report);
      });

      this.reportOptions = Array.from(reportOptions.values());

      this.state = LoadingState.loaded;
    });
  }

  navigateToReport(report: PowerBIReportInformationDTO): void {
    this.router.navigate(['/reports'], {
      queryParams: {
        path: report.reportTypeName
      }
    });
  }
}
