import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Contract} from 'src/app/models/contract/contract';
import {ResponsePayloadDTO} from 'src/app/models/salesAPIResponse';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  updateClientContract(contractId: number, template: Contract): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.put<ResponsePayloadDTO<boolean>>(`${environment.projectApiBaseUrl}/Contract/contracts/${contractId}`, template);
  }

  getContractById(contractId: number): Observable<ResponsePayloadDTO<Contract>> {
    return this.httpClient.get<ResponsePayloadDTO<Contract>>(`${environment.projectApiBaseUrl}/Contract/contracts/${contractId}`);
  }
}
