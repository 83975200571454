import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GlobalCalloutComponent} from "./components/global-callout/global-callout.component";
import {filter, Observable, Subject, takeUntil} from "rxjs";
import {UserService} from "../../services/user/user.service";
import {Select, Store} from "@ngxs/store";
import {GeneralStateModel} from "../../state/general/models/general-state.model";
import {GeneralState} from "../../state/general/general.state";
import {
  SidebarMenuOption
} from "../../../../projects/fieldflow-ui/src/lib/components/field-flow-sidebar/models/sidebar-menu-option";
import {DeliverySearchComponent} from "./components/delivery-search/delivery-search.component";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {UtilityService} from "../../services/utility/utility.service";
import {ResetDeliverySearch} from "../../state/delivery-search/delivery-search.state";
import {DashboardsSidebarComponent} from "./sidebars/dashboards-sidebar/dashboards-sidebar.component";
import {ReportsSidebarComponent} from "./sidebars/reports-sidebar/reports-sidebar.component";

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css']
})
export class SiteLayoutComponent implements OnInit, OnDestroy {

  @ViewChild(GlobalCalloutComponent) globalCalloutComponent: GlobalCalloutComponent;
  componentDestroyed$ = new Subject<void>();
  @Select(GeneralState) generalState$!: Observable<GeneralStateModel>;
  // TODO: Update after released
  utilizeNativeCopilot: boolean = false;
  name: string = '';
  role: string = '';

  sidebarOptions: SidebarMenuOption[] = [];

  constructor(private userService: UserService, private store: Store, private router: Router, private utilityService: UtilityService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.sidebarOptions = [
      {
        icon: 'dashboard',
        title: 'Dashboards',
        subMenu: DashboardsSidebarComponent
      },
      {
        icon: 'bar_chart_4_bars',
        title: 'Reports',
        subMenu: ReportsSidebarComponent
      },
      {
        icon: 'psychiatry',
        title: 'Opportunities',
        route: '/sales/deal',
        token: 'opportunities'
      },
      {
        icon: 'quick_reorder',
        title: 'Delivery',
        token: 'delivery',
        subMenu: DeliverySearchComponent
      },
      {
        icon: 'tools_ladder',
        title: 'Vendors',
        route: '/vendors',
        token: 'vendors'
      },
      {
        icon: 'people',
        title: 'Technicians',
        route: '/technicians',
        token: 'technicians'
      },
      {
        icon: 'store',
        title: 'Clients',
        route: '/clients',
        token: 'clients'
      },
    ];

    // Determine if any delivery search items are present in the route. If not, clear the state.
    const matchTokens = [
      'delivery',
      'ticket'
    ];
    this.router.events.pipe(
      takeUntil(this.componentDestroyed$),
      filter((e) => e instanceof NavigationEnd)
    ).subscribe(() => {
      const matchedRoutes = this.utilityService.getMatchingRoutes(this.activatedRoute.root, 'sidebarToken', matchTokens);
      if (matchedRoutes.size === 0) {
        this.store.dispatch(new ResetDeliverySearch());
      }
    });

    this.generalState$.pipe(
      takeUntil(this.componentDestroyed$),
      filter((state) => !!state.authState?.user)
    ).subscribe((state) => {
      this.name = state.authState!.user.displayName;
      this.role = state.authState!.user.roles[0]?.name;
    });
  }

  searchClicked(): void {
    if (this.utilizeNativeCopilot) {
      this.globalCalloutComponent.toggleVisibility();

      return;
    }

    window.open('https://copilot.dispatch1.com');
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  logOut() {
    this.userService.logout();
  }
}
