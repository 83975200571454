import {Injectable} from "@angular/core";
import * as LDClient from 'launchdarkly-js-client-sdk';
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {

  /**
   * Subject that emits when the client is initialized.
   */
  public initialize$ = new ReplaySubject<void>();

  // Null indicates that the client has not been initialized
  private _client: LDClient.LDClient | null = null;
  // Map of feature flags used to notify listeners of changes
  private _observers: Map<string, BehaviorSubject<boolean>> = new Map<string, BehaviorSubject<boolean>>();

  /**
   * True if the LaunchDarkly client has been initialized.
   */
  get hasInitialized(): boolean {
    return this._client != null;
  }

  /**
   * Initialize the LaunchDarkly client
   * @returns {Observable<void>} An observable that completes when the client is initialized or errors if the client fails to initialize.
   */
  initialize(clientId: string): Observable<void> {
    // Always initialize the client as an anonymous user. Functionality is exposed to allow this to be updated in the proper location.
    this._client = LDClient.initialize(clientId, {
      kind: 'user',
      anonymous: true,
    });

    this.initialize$.next();

    this._client.on('change', () => {
      this._notifyListeners();
    });

    return new Observable<void>((observer) => {
      this._client!.waitForInitialization().then(() => {
        // In-case any listeners were registered prior to initialization, notify them of the current state.
        this._notifyListeners();

        observer.next();
        observer.complete();
      }).catch((error) => {
        observer.error(error);
      });
    });
  }

  /**
   * Get the value of a feature flag.
   * @param flagKey The key of the feature flag in LaunchDarkly.
   * @param defaultValue The default value of the feature flag. Returned if the flag is not registered in LaunchDarkly or if the client fails to initialize/is not yet initialized.
   * @returns {Observable<boolean>} An observable that emits the value of the feature flag.
   */
  variation(flagKey: string, defaultValue: boolean): Observable<boolean> {
    if (this._client == null) {
      throw new Error('LaunchDarkly client is not initialized');
    }

    const flagValue: boolean = this._client.allFlags()[flagKey] ?? defaultValue;

    if (!this._observers.has(flagKey)) {
      const newSubject = new BehaviorSubject<boolean>(flagValue);
      this._observers.set(flagKey, newSubject);

      return newSubject.asObservable();
    }

    return this._observers.get(flagKey)!.asObservable();
  }

  /**
   * Update the LaunchDarkly client to a new user context.
   * @param user The user to identify as.
   */
  identify(user: IUser): Observable<void> {
    if (this._client == null) {
      throw new Error('LaunchDarkly client is not initialized');
    }

    return new Observable<void>((observer) => {
      this._client!.identify({
        kind: 'user',
        anonymous: false,
        key: user.id,
        name: user.name,
        email: user.email,
      }).then(() => {
        this._notifyListeners();

        observer.next();
        observer.complete();
      }).catch((error) => {
        observer.error(error);
      });
    });
  }

  /**
   * Reset the LaunchDarkly client to an anonymous context. This reverts flag evaluation to its defaults.
   */
  resetToAnonymousContext(): Observable<void> {
    if (this._client == null) {
      throw new Error('LaunchDarkly client is not initialized');
    }

    return new Observable<void>((observer) => {
      this._client!.identify({
        kind: 'user',
        anonymous: true,
      }).then(() => {
        observer.next();
        observer.complete();
      }).catch((error) => {
        observer.error(error);
      });
    });
  }

  /**
   * Tear down the LaunchDarkly client and complete all listeners.
   */
  tareDown(): Observable<void> {
    if (this._client == null) {
      throw new Error('LaunchDarkly client is not initialized');
    }

    this.initialize$.complete();

    this._observers.forEach((value) => {
      value.complete();
    });
    this._observers.clear();

    return new Observable((observer) => {
      this._client!.close().then(() => {
        observer.next();
        observer.complete();
      })
    });
  }

  private _notifyListeners(): void {
    const flags = this._client!.allFlags();

    for (const [key, value] of Object.entries(flags)) {
      if (this._observers.has(key)) {
        this._observers.get(key)!.next(value);
      }
    }
  }

}

/**
 * Interface for a user in LaunchDarkly.
 */
export interface IUser {
  id: string;
  name: string;
  email: string;
}
