import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  FieldFlowDialogModule
} from "../../../../../projects/fieldflow-ui/src/lib/components/fieldflow-dialog/fieldflow-dialog.module";
import {ConfirmationDialogComponent} from "./confirmation-dialog.component";
import {
  FieldFlowButtonModule
} from "../../../../../projects/fieldflow-ui/src/lib/components/field-flow-button/field-flow-button.module";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    FieldFlowDialogModule,
    FieldFlowButtonModule,
    MatDialogModule
  ],
  exports: [
    ConfirmationDialogComponent
  ]
})
export class ConfirmationDialogModule {
}
