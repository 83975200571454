<head>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css">
</head>
<div class="background rgba-gradient">
    <div class="container">
        <div class="row" style="margin-top: 10rem;">
            <div class="col-md-5 col-xl-5 col-xs-2">
                <div class="box">
                    <h2 class="main-heading">DISPATCH<span style="color:#2BAAE2;font-weight:bold;">&#9312;</span></h2>
                    <h1 class="sub-heading">Global Field Techs</h1>
                    <h6 class="description">Flexible, on demand, 24/7/365 global dispatches serving IT service
                        providers, telecommunications, managed service providers & retail IT</h6>
                    <br>
                </div>
            </div>

            <div class="col-xl-5 offset-xl-2">
                <div class="text-center">
                    <div class="right-box img-responsive">
                        <img src="../../../assets/images/office_365.jpg" style="width:48%;margin-top:26px;" alt="">
                        <p style="margin-right:1rem;color: rgba(85, 86, 90, 0.7);">Office 365 single sign on</p>
                        <button tabindex="3" (click)="login()" class="btn signin-btn">LOGIN</button>
                    </div>
                    <div style="text-align:center">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>