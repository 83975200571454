import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, Subject, catchError, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'd1-filter-builder',
  templateUrl: './filter-builder.component.html',
  styleUrls: ['./filter-builder.component.css']
})
export class FilterBuilderComponent implements OnInit, OnDestroy {

  constructor(private toastr: ToastrService) { }

  componentDestroyed$ = new Subject<void>();

  filterFormGroup: FormGroup = new FormGroup({});

  @Input() filterGroups: D1FilterGroup[] = [];

  optionTypes = OptionType;

  dropdownFilterOptionsBuffer: Map<string, D1FilterDropdownOption[]> = new Map<string, D1FilterDropdownOption[]>();

  @Output() filterValueChange = new EventEmitter();

  ngOnInit(): void {
    // Define all dropdown options by either setting static options or subscribing to an observable
    this.filterGroups.forEach((group) => {
      group.items.forEach((element) => {
        this.filterFormGroup.addControl(element.key, new FormControl());

        if(element instanceof D1FilterElementDropdown) {
          if(element.optionObservable != null) {
            element.optionObservable.pipe(
              takeUntil(this.componentDestroyed$),
              catchError(() => {
                this.toastr.error(`Failed to load dropdown options for dropdown "${element.label}".`, 'Error');

                return EMPTY;
              }),
            ).subscribe((options) => {
              this.dropdownFilterOptionsBuffer.set(element.key, options);
            });

            return;
          }

          this.dropdownFilterOptionsBuffer.set(element.key, element.options!);
        }
      });
    });

    this.filterFormGroup.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      takeUntil(this.componentDestroyed$),
    ).subscribe((value) => {
      this.filterValueChange.emit(value);
    });
  }

  parseDropdownType(element: D1FilterElement): OptionType {
    if(element instanceof D1FilterElementDropdown) {
      return OptionType.dropdown;
    }

    if(element instanceof D1FilterElementText) {
      return OptionType.text;
    }

    if(element instanceof D1FilterRange) {
      return OptionType.range;
    }

    throw new Error('Please reference valid superclasses of D1FilterElement');
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  convertFromSuperToDropdown(from: D1FilterElement): D1FilterElementDropdown {
    return from as D1FilterElementDropdown;
  }

}

export class D1FilterDropdownOption {
  label: string;
  index: number;
}

export class D1FilterGroup {
  label?: string | null;
  items: D1FilterElement[];
}

export abstract class D1FilterElement {
  constructor(key: string, label: string) {
    this.label = label;
    this.key = key;
  }

  key: string;
  label: string;
}

export class D1FilterElementText extends D1FilterElement {}
export class D1FilterRange extends D1FilterElement {}

export interface D1FilterElementDropdownOptions {
  optionObservable?: Observable<D1FilterDropdownOption[]> | null;
  options?: D1FilterDropdownOption[] | null;
  enableMultiSelect?: boolean | null;
}

export class D1FilterElementDropdown extends D1FilterElement {
  constructor(key: string, label: string, options: D1FilterElementDropdownOptions) {
    super(key, label);

    this.options = options.options;
    this.optionObservable = options.optionObservable;
    this.enableMultiSelect = options.enableMultiSelect;

    if(this.options == null && this.optionObservable == null) {
      throw new Error('Dropdown must have options or an option observable');
    }
  }

  optionObservable?: Observable<D1FilterDropdownOption[]> | null;
  options?: D1FilterDropdownOption[] | null;
  enableMultiSelect?: boolean | null;
}

export enum OptionType {
  text = 1,
  dropdown = 2,
  range = 3,
}