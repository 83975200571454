import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponsePayloadDTO } from 'src/app/models/salesAPIResponse';
import { ResponseInterval } from 'src/app/models/vendor/vendor';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResponseIntervalService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getAllResponseIntervals(): Observable<ResponsePayloadDTO<ResponseInterval[]>> {
    return this.httpClient.get<ResponsePayloadDTO<ResponseInterval[]>>(`${environment.configurationsApiBaseUrl}/response-intervals`);
  }

  getResponseIntervalById(id: number): Observable<ResponsePayloadDTO<ResponseInterval>> {
    return this.httpClient.get<ResponsePayloadDTO<ResponseInterval>>(`${environment.configurationsApiBaseUrl}/response-intervals/${id}`);
  }

  saveResponseInterval(template: ResponseInterval): Observable<ResponsePayloadDTO<number>> {
    return this.httpClient.post<ResponsePayloadDTO<number>>(`${environment.configurationsApiBaseUrl}/response-intervals`, template);
  }

  updateResponseInterval(template: ResponseInterval): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.put<ResponsePayloadDTO<boolean>>(`${environment.configurationsApiBaseUrl}/response-intervals`, template);
  }

  deleteResponseInterval(id: number): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.delete<ResponsePayloadDTO<boolean>>(`${environment.configurationsApiBaseUrl}/response-intervals/${id}`);
  }

}
