import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TechnicianLevelService {
  private readonly urlPath = `${environment.configurationsApiBaseUrl}/technicianLevels`;
  constructor(private httpClient: HttpClient) { }

  create(request: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.urlPath}`,
        request).pipe();
  }
  update(id:number,request: any): Observable<any> {
    return this.httpClient
      .put<any>(`${this.urlPath}`,
        request).pipe();
  }
  getAll(): Observable<any> {
    return this.httpClient.get<any>(`${this.urlPath}`).pipe();
  }
  getById(id:number): Observable<any> {
    return this.httpClient.get<any>(`${this.urlPath}/${id}`).pipe();
  }
  delete(id:number): Observable<any> {
    return this.httpClient.delete<any>(`${this.urlPath}/${id}`).pipe();
  }
}
