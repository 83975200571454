<div [ngStyle]="{'background-color': color}" class="background"></div>
<div [ngStyle]="{'border-color': color}" class="content-wrapper">
  <div class="header">
    <mat-icon [ngStyle]="{'color': color}" fontSet="material-symbols-outlined">{{ icon }}</mat-icon>
    <h4>{{ title }}</h4>
  </div>

  <div class="content">

    <div class="primary">
      <ng-content></ng-content>
    </div>

    <div class="action">

      <ng-content select="fieldflow-button"></ng-content>

    </div>

  </div>
</div>
