import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fieldflow-section-card',
  templateUrl: './field-flow-section-card.component.html',
  styleUrls: ['./field-flow-section-card.component.css']
})
export class FieldFlowSectionCardComponent implements OnInit {

  @Input() title: string = '';
  @Input() description?: string;
  @Input() icon?: string;
  @Input() showIndicator: boolean = false;
  @Input() indicatorColor: string = '#e74c3c';
  @Input() cardPerElement: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
