import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'lib-navbar-dropdown',
  templateUrl: './navbar-dropdown.component.html',
  styleUrls: ['./navbar-dropdown.component.css'],
  animations: [
    trigger('dropdownReveal', [
      state('fadeIn', style({opacity: 1, height: '*'})),
      state('fadeOut', style({opacity: 0, height: 75})),
      transition('fadeOut => fadeIn', [animate('0.1s')]),
      transition('fadeIn => fadeOut', [animate('0.1s')]),
    ])
  ]
})
export class NavbarDropdownComponent implements OnInit {

  @Input() state: 'fadeOut' | 'fadeIn' = 'fadeOut';
  removed: boolean = true;
  @Input() title: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  animationCompleted() {
    if (this.state === 'fadeOut') {
      this.removed = true;
    }
  }

  animationStarted() {
    if (this.state === 'fadeIn') {
      this.removed = false;
    }
  }

}
