import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {EmailContent} from 'src/app/models/utility/email-content';
import {environment} from 'src/environments/environment';
import {ApplicationTypes} from '../user/models/user-application-information';
import {ToastrService} from 'ngx-toastr';
import {DashboardsEnum} from 'src/app/shared/enum/dashboards-enum';
import {UserService} from '../user/user.service';
import {
  D1FilterDropdownOption,
  D1FilterElementDropdown,
  D1FilterElementText,
  D1FilterGroup,
  D1FilterRange
} from 'src/app/shared/components/filter-builder/filter-builder.component';
import {ResponsePayloadDTO} from 'src/app/models/salesAPIResponse';
import {LoadingState} from "../../state/loading-state.enum";
import {ActivityType, ResponseInterval} from 'src/app/models/activity/activity';
import {ActivityStatus} from 'src/app/models/activity/activity-status';
import {ActivityCategory} from 'src/app/models/activity/activity-category';
import {Currency} from 'src/app/models/activity/currency';
import {IGetMilestoneModel} from '../../api-services/milestone-api/milestone/models/get-milestone.model';
import {MilestoneService} from '../../api-services/milestone-api/milestone/milestone.service';
import {CompetencyService} from '../competency/competency.service';
import {RegionService} from '../region/region.service';
import {TechnicianLevelService} from '../technician-level/technician-level.service';
import {ResponseIntervalService} from '../response-interval/response-interval.service';
import {ActivityService} from '../activity/activity.service';
import {TableFilters} from 'src/app/shared/common/tableFilters';
import {CurrencyPipe} from '@angular/common';
import DOMPurify from 'dompurify';
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  user: any;
  permissions: any;
  public myDashboardLink = '';


  private showMyDashboardSource = new BehaviorSubject<boolean>(false);
  currentShowMyDashboard = this.showMyDashboardSource.asObservable();

  private myDashboardLinkSource = new BehaviorSubject<string>('');
  currentMyDashboardLink = this.myDashboardLinkSource.asObservable();

  constructor(
    private httpClient: HttpClient, private toastr: ToastrService, private userService: UserService,
    private milestoneService: MilestoneService,
    private competencyService: CompetencyService,
    private regionService: RegionService,
    private technicianLevelService: TechnicianLevelService,
    private responseIntervalService: ResponseIntervalService,
    private activityService: ActivityService,
    private currencyPipe: CurrencyPipe,
  ) {
    this.setUserPermissions();
  }

  applicationAccessEnabled(): boolean {
    const applicationTypes: any[] = this.user?.results?.applicationTypes;

    const res = !applicationTypes?.every((type) => type.id !== ApplicationTypes.core);

    if (!res) {
      this.toastr.error('Access Dispatch1 is not enabled on your account. Please contact a system administrator if you believe this is a mistake.');
    }

    return res ?? false;
  }

  mergeLoadingStates(...loadingStates: LoadingState[]): LoadingState {
    if (loadingStates.includes(LoadingState.loading)) {
      return LoadingState.loading;
    }

    if (loadingStates.includes(LoadingState.error)) {
      return LoadingState.error;
    }

    if (loadingStates.includes(LoadingState.invalid)) {
      return LoadingState.invalid;
    }

    return LoadingState.loaded;
  }

  /**
   * Traverse the router tree to find the activated routes that match the tokens.
   * @param activatedRoute The activated route to start the search from.
   * @param dataKey The key that the route data will be checked for.
   * @param matchValues The values that the route data should match against.
   * @returns A map of the matched routes, where the key is the matched value, and the value is the activated route.
   */
  getMatchingRoutes(activatedRoute: ActivatedRoute, dataKey: string, matchValues: string[]): Map<string, ActivatedRoute> {
    const matchedRoutes = new Map<string, ActivatedRoute>();

    if (matchValues.includes(activatedRoute.snapshot.data[dataKey])) {
      matchedRoutes.set(activatedRoute.snapshot.data[dataKey], activatedRoute);
    }

    if (activatedRoute.children.length > 0) {
      const childBuffer: Map<string, ActivatedRoute>[] = [];
      activatedRoute.children.forEach(child => {
        childBuffer.push(this.getMatchingRoutes(child, dataKey, matchValues));
      });

      // Merge the child buffers into the matched routes.
      childBuffer.forEach(childMatchedTokens => {
        childMatchedTokens.forEach((value, key) => {
          matchedRoutes.set(key, value);
        });
      });
    }

    return matchedRoutes;
  }

  hasPermission(permissionName: string) {
    if (this.permissions != null) {
      const permisison = this.permissions.find(t => t.name === permissionName);
      return permisison != null || permisison != undefined;
    } else {
      this.setUserPermissions();
      if (this.permissions != null) {
        const permisison = this.permissions.find(t => t.name === permissionName);
        return permisison != null || permisison != undefined;
      }
    }
    return false;
  }

  sendEmail(emailContent: EmailContent): Observable<any> {
    return this.httpClient.post<any>(`${environment.utilityApiBaseUrl}/sendemail`, emailContent);
  }

  setUserPermissions() {
    this.user = JSON.parse(localStorage.getItem('user_profile') || sessionStorage.getItem('user_profile') || '{}');
    this.permissions = this.user?.permissions;
  }

  changeShowMyDashboard(value: boolean) {
    this.showMyDashboardSource.next(value);
  }

  changeMyDashboardLink(value: string) {
    this.myDashboardLinkSource.next(value);
  }

  determineUserLandingPage(profile: any) {
    const rolesWithDashboardId = profile['roles'].filter(x => x.dashboardId != null);
    if (rolesWithDashboardId.length > 0) {
      const defaultDashboardId = rolesWithDashboardId[0]['dashboardId'];
      if (DashboardsEnum[defaultDashboardId] == "Task_Dashboard") {
        this.changeShowMyDashboard(true);
        this.changeMyDashboardLink('/dashboards/task-dashboard');
      }
    }
  }

  generateQueryParamsFromQuery(query: any, ignoredKeys: string[]): HttpParams | null {
    if (query == null) {
      return null;
    }

    const rawParams = Object.entries(query);
    let params = new HttpParams();

    rawParams.forEach((param) => {
      if (param[1] != null && !ignoredKeys.includes(param[0])) {
        params = params.append(param[0], param[1] as string | number | boolean);
      }
    });

    return params;
  }

  mapResourceToDropdownOption<T>(
    resourceService: any,
    resourceProperty: any,
    label: any,
    index: any
  ): Observable<D1FilterDropdownOption[]> {
    return resourceService[resourceProperty]().pipe(
      map<ResponsePayloadDTO<T[]>, D1FilterDropdownOption[]>((types) => {
        return types.results.map<D1FilterDropdownOption>((type: any) => {
          return {
            label: type[label],
            index: type[index],
          };
        });
      }),
    );
  }

  getActivityFilterGroups(): D1FilterGroup[] {
    return [
      {
        label: 'Filter Name',
        items: [
          new D1FilterElementText('activityName', 'Activity Name...'),
          new D1FilterElementText('displayName', 'Display Name...')
        ],
      },
      {
        label: "",
        items: [
          new D1FilterElementDropdown('typeId', 'Type', {
            optionObservable: this.activityService.getActivityTypes().pipe(
              map<ResponsePayloadDTO<ActivityType[]>, D1FilterDropdownOption[]>((types) => {
                return types.results.map<D1FilterDropdownOption>((type) => {
                  return {
                    label: type.type,
                    index: type.id,
                  };
                });
              }),
            ),
          }),
          new D1FilterElementDropdown('statusId', 'Status', {
            optionObservable: this.activityService.getActivityStatuses().pipe(
              map<ResponsePayloadDTO<ActivityStatus[]>, D1FilterDropdownOption[]>((types) => {
                return types.results.map<D1FilterDropdownOption>((type) => {
                  return {
                    label: type.name!,
                    index: type.id,
                  };
                });
              }),
            )
          }),
          new D1FilterElementDropdown('categoryId', 'Category', {
            optionObservable: this.activityService.getActivityCategories().pipe(
              map<ResponsePayloadDTO<ActivityCategory[]>, D1FilterDropdownOption[]>(
                (types) => {
                  return types.results.map<D1FilterDropdownOption>((type) => {
                    return {
                      label: type.name!,
                      index: type.id,
                    };
                  });
                }
              )
            ),
          })
        ],
      },
      {
        label: "",
        items: [
          new D1FilterElementDropdown('currencyId', 'Currency', {
            optionObservable: this.activityService.getCurrencies().pipe(
              map<ResponsePayloadDTO<Currency[]>, D1FilterDropdownOption[]>(
                (types) => {
                  return types.results.map<D1FilterDropdownOption>((type) => {
                    return {
                      label: type.name!,
                      index: type.id,
                    };
                  });
                }
              )
            ),
          }),
          new D1FilterElementDropdown('milestoneId', 'Milestones', {
            optionObservable: this.milestoneService.getAllMilestones().pipe(
              map<IGetMilestoneModel[], D1FilterDropdownOption[]>(
                (types) => {
                  return types.map<D1FilterDropdownOption>((type, index) => {
                    return {
                      label: type.name!,
                      index: index,
                    };
                  });
                }
              )
            ),
          }),
          new D1FilterElementDropdown('competencyId', 'Competencies', {
            optionObservable: this.competencyService.getPagedCompetencies(new TableFilters({page: 0})).pipe(
              map<any, D1FilterDropdownOption[]>(
                (types) => {
                  const competencyList: any[] = types.results.data;
                  return competencyList.map<D1FilterDropdownOption>((type) => {
                    return {
                      label: type.name,
                      index: type.competencyTypeId,
                    };
                  });
                }
              )
            ),
          }),
        ]
      },
      {
        label: "",
        items: [
          new D1FilterElementDropdown('regionId', 'Region', {
            optionObservable: this.regionService.getPagedRegions(new TableFilters({page: 0})).pipe(
              map<ResponsePayloadDTO<any[]>, D1FilterDropdownOption[]>(
                (types) => {
                  return types.results.map<D1FilterDropdownOption>((type) => {
                    return {
                      label: type.name,
                      index: type.id,
                    };
                  });
                }
              )
            ),
          }),
          new D1FilterElementDropdown('technicianLevelId', 'Technician Level', {
            optionObservable: this.technicianLevelService.getAll().pipe(
              map<ResponsePayloadDTO<any[]>, D1FilterDropdownOption[]>(
                (types) => {
                  return types.results.map<D1FilterDropdownOption>((type) => {
                    return {
                      label: type.name,
                      index: type.id,
                    };
                  });
                }
              )
            ),
          }),
          new D1FilterElementDropdown('responseId', 'Response Interval', {
            optionObservable: this.responseIntervalService.getAllResponseIntervals().pipe(
              map<ResponsePayloadDTO<ResponseInterval[]>, D1FilterDropdownOption[]>(
                (types) => {
                  return types.results.map<D1FilterDropdownOption>((type) => {
                    return {
                      label: type.name,
                      index: type.id,
                    };
                  });
                }
              )
            ),
          })
        ],
      },
      {
        label: 'Price',
        items: [
          new D1FilterRange('priceFrom', 'Price From'),
          new D1FilterRange('priceTo', 'Price To')
        ],
      },
    ];
  }

  pipeToCurrency(value: number | string): string {
    return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2')!;
  }

  parseJSON(jsonString: string): any {
    const cleanJsonString = jsonString?.replace(/^```json|```$/g, '');
    return JSON.parse(cleanJsonString);
  }

  sanitizeHTML(htmlString: string = ""): string {
    return htmlString?.replace(/<[^>]*>|&nbsp;/g, '');
  }

  domPurifySanitizeHtml(html: string, allowedTags: string[]): string {
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if ('tagName' in node && !allowedTags.includes(node.tagName.toLowerCase())) {
        node.remove();
      }
    });
    return DOMPurify.sanitize(html);
  }

  validateExpirationDate(expirationDate): boolean {
    return new Date(expirationDate ?? "").setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  }

  validateEmail(item: string) {
    const EMAIL_REGEXP = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
    return EMAIL_REGEXP.test(item);
  }
}
