import {Component, OnDestroy, OnInit} from '@angular/core';
import {IconService} from 'carbon-components-angular';

import Notification20 from '@carbon/icons/es/notification/20';
import UserAvatar20 from '@carbon/icons/es/user--avatar/20';
import View20 from '@carbon/icons/es/view/20';
import Edit16 from '@carbon/icons/es/edit/16';
import Location16 from '@carbon/icons/es/location/16';
import Launch16 from '@carbon/icons/es/launch/16';
import Phone16 from '@carbon/icons/es/phone/16';
import Email16 from '@carbon/icons/es/email/16';
import Filter16 from '@carbon/icons/es/filter/16';
import Search16 from '@carbon/icons/es/search/16';
import TrashCan16 from '@carbon/icons/es/trash-can/16';
import Information20 from '@carbon/icons/es/information/20';
import {
  Add20,
  Archive16,
  ChangeCatalog16,
  CheckmarkOutline20,
  ChevronLeft20,
  CloseOutline20,
  Dashboard16,
  DirectionStraightFilled16,
  DocumentAdd20,
  DocumentBlank16,
  Edit20,
  ExpandAll20,
  FaceAdd20,
  IbmCloudPakIntegration20,
  LicenseMaintenance20,
  OperationsRecord20,
  Screen16,
  SendAlt16,
  Ticket16,
  TrashCan20,
  UserIdentification20
} from '@carbon/icons';
import {MsalService} from '@azure/msal-angular';
import {NavigationEnd, Router} from '@angular/router';
import {UserService} from './services/user/user.service';
import {UtilityService} from './services/utility/utility.service';
import {FeatureFlagsService} from "./services/feature-flags/feature-flags.service";
import {RealTimeStatusService} from "./api-services/milestone-api/real-time-status/real-time-status.service";
import {LoadingState} from "./state/loading-state.enum";
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  forkJoin,
  map,
  Observable,
  Subject,
  takeUntil
} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {GeneralState, SetAuthState} from './state/general/general.state';
import {environment} from "../environments/environment";
import {RealTimeStatusModel} from "./api-services/milestone-api/real-time-status/models/real-time-status.model";
import {ITaskManagementStateModel} from "./shared/components/task-sidebar/state/models/task-management-state.model";
import {GetStatusesForResources} from "./state/status/status.state";
import {LoadOverviewForHierarchy} from "./shared/components/task-sidebar/state/task-management.state";
import {ToastrService} from "ngx-toastr";
import * as Sentry from "@sentry/angular-ivy";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Sales Portal';

  loadingState: LoadingState = LoadingState.loading;
  hasInitializedFeatureFlags = false;
  readonly LoadingState = LoadingState;
  @Select(GeneralState.loggedInUser) user$!: Observable<any | null>;

  loginState$ = new Subject<boolean>();

  componentDestroyed$ = new Subject<void>()

  constructor(private toastr: ToastrService, private router: Router, private iconService: IconService, private msalService: MsalService, private userService: UserService, private utility: UtilityService, private flag: FeatureFlagsService, private realTimeStatusService: RealTimeStatusService, private store: Store) {
  }

  ngOnInit() {
    const navigationEndObservable = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event) => event as NavigationEnd)
    );

    this.user$.pipe(
      takeUntil(this.componentDestroyed$),
      distinctUntilChanged()
    ).subscribe(user => {
      if (user && !this.hasInitializedFeatureFlags) {
        this.hasInitializedFeatureFlags = true;
        this.flag.initialize(environment.ldClientId).subscribe(() => {
          this.flag.identify({
            id: user.id,
            name: user.displayName,
            email: user.mail
          }).subscribe();
        });
      }

      if (user && environment.production) {
        Sentry.setUser({
          id: user.id,
          username: user.displayName,
          email: user.mail
        });
      }
    });

    combineLatest([navigationEndObservable, this.loginState$]).pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(([event, loggedIn]) => {
        if (loggedIn && (event.url === '/login' || event.urlAfterRedirects === '/login')) {
          this.router.navigate(['dashboards/task-dashboard']);
        }
      }
    );

    this.iconService.registerAll([
      Notification20,
      UserAvatar20,
      View20,
      Edit16,
      Location16,
      Launch16,
      Phone16,
      Email16,
      Filter16,
      Search16,
      TrashCan16,
      ChevronLeft20,
      TrashCan20,
      Edit20,
      FaceAdd20,
      DocumentAdd20,
      Add20,
      UserIdentification20,
      LicenseMaintenance20,
      OperationsRecord20,
      IbmCloudPakIntegration20,
      ExpandAll20,
      SendAlt16,
      Archive16,
      DirectionStraightFilled16,
      CheckmarkOutline20,
      CloseOutline20,
      ChangeCatalog16,
      Ticket16,
      Dashboard16,
      DocumentBlank16,
      Screen16,
      Information20,
      // AppSwitcher20
    ]);

    this.msalService.handleRedirectObservable().pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((result) => {
      if (result) {
        this.msalService.instance.setActiveAccount(result.account);

        this.loadingState = LoadingState.loading;
        this.makePrerequisiteRequests(result.account!.localAccountId, true);
      }

      let account = this.msalService.instance.getActiveAccount();

      // If there is no active account, but there are accounts in the cache, set the first account as the active account.
      if (!account && this.msalService.instance.getAllAccounts().length > 0) {
        this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);

        account = this.msalService.instance.getActiveAccount();
      }

      if (account) {
        this.makePrerequisiteRequests(account.localAccountId, false);
      }

      this.loginState$.next(!!account);

      // If there is no active account, redirect to login page.
      if (!account) {
        this.loadingState = LoadingState.loaded;
        this.router.navigate(['login']);
      }
    });
  }

  ngOnDestroy() {
    this.flag.tareDown().subscribe();
    this.realTimeStatusService.closeConnection().subscribe();

    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private makePrerequisiteRequests(userId: string, navigateToLandingPage: boolean): void {
    this.loadingState = LoadingState.loading;
    forkJoin([this.userService.getJwtToken(), this.userService.saveLocalUser({UserId: userId}), this.msalService.acquireTokenSilent({
      scopes: environment.b2cScopes,
    })]).pipe(
      takeUntil(this.componentDestroyed$),
      catchError(() => {
        this.loadingState = LoadingState.error;

        this.userService.logout();
        this.toastr.error('Error establishing connection to identity service. Please refresh the page and try again.');

        return EMPTY;
      })
    ).subscribe(([token, _, msalToken]) => {
      // TODO: Remove references to tokens via localStorage.
      const jwtToken = token.results;
      localStorage.setItem('jwtToken', JSON.stringify(jwtToken));

      this.userService.getCurrentUserProfile().subscribe((profile) => {
        // TODO: Remove references to tokens via localStorage.
        localStorage.setItem('user_profile', JSON.stringify(profile.results));
        localStorage.setItem('idToken', JSON.stringify(msalToken.accessToken));
        localStorage.setItem('b2CUserId', JSON.stringify(msalToken.uniqueId));
        localStorage.setItem('userId', JSON.stringify(profile.results.id));

        this.utility.setUserPermissions();
        this.store.dispatch(new SetAuthState({
          user: profile.results,
          token: token.results,
          msalToken: msalToken.accessToken,
        }));

        this.configureRealTimeStatuses(msalToken.uniqueId, msalToken.accessToken);

        this.utility.determineUserLandingPage(profile.results);

        this.loadingState = LoadingState.loaded;

        if (navigateToLandingPage) {
          this.router.navigate(['/']);
        }
      });
    });
  }

  private configureRealTimeStatuses(userId: string, token: string): void {
    this.realTimeStatusService.establishConnection(token).pipe(
      catchError(() => {
        this.toastr.error('Error establishing connection to real-time status service. Contract/Ticket/PO statuses may not accurately reflect real-time data.');
        return EMPTY;
      })
    ).subscribe(() => {
      this.realTimeStatusService.listen<RealTimeStatusModel>('RealTimeStatusUpdate').subscribe(data => {
        if (data.changeCausedByUserId === userId) {
          this.toastr.info(`Task "${data.affectedTask.name}" has been automatically completed.`, 'Automatic Task Completion');
          this.store.selectSnapshot(state => {
            const taskManagementState: ITaskManagementStateModel | null = state.taskManagement;

            this.store.dispatch(new GetStatusesForResources([data.resourceHierarchy]));

            // Determine if the task management state is initialized
            if (!taskManagementState || !taskManagementState.hierarchy) {
              return;
            }

            // Reload the overview if the hierarchy matches the affected resource
            if (taskManagementState.hierarchy.contractId === data.resourceHierarchy.contractId && taskManagementState.hierarchy.ticketId === data.resourceHierarchy.ticketId && taskManagementState.hierarchy.purchaseOrderId === data.resourceHierarchy.purchaseOrderId) {
              this.store.dispatch(new LoadOverviewForHierarchy(taskManagementState.hierarchy, true));
            }
          });
        }
      });
    });
  }
}
