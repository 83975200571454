import { Filters } from './../../models/common/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractTicketRequest, TicketFinancialsDto, TicketUploadResultDto } from 'src/app/models/deliveryContract/delivery-contract.model';
import { ResponsePayloadDTO } from 'src/app/models/salesAPIResponse';
import { environment } from 'src/environments/environment';
import { TableFilters } from 'src/app/shared/common/tableFilters';

@Injectable({
	providedIn: 'root'
})
export class DeliveryContractService {

	constructor(private httpClient: HttpClient) { }



	getTickets(payload: TableFilters, contractId: number): Observable<any> {

		return this.httpClient.get<any>(`${environment.workOrderApiBaseUrl}/contract/${contractId}/tickets?sort=${payload.sort}&search=${payload.search}&page=${payload.page}&pageSize=${payload.pageSize}&isAscending=${payload.isAscending}`);
	}

	createTickets(client: ContractTicketRequest): Observable<any> {
		return this.httpClient.post<ContractTicketRequest>(
			`${environment.workOrderApiBaseUrl}/contract/tickets`,
			client
		);
	}
	deleteTicketById(id: number, deleteNote: string, noteTitle: string): Observable<ResponsePayloadDTO<boolean>> {
		let params = new HttpParams()
			.set('deleteNote', deleteNote)
			.set('noteTitle', noteTitle);

		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.workOrderApiBaseUrl}/contract/${id}`,
			{ params: params }
		);
	}

	getContractFinancialsById(contractId: number): Observable<ResponsePayloadDTO<TicketFinancialsDto>> {
		return this.httpClient.get<ResponsePayloadDTO<TicketFinancialsDto>>(`${environment.workOrderApiBaseUrl}/contract/${contractId}/financials`)
	}

	downloadTicketFormTemplateURL(): Observable<Blob> {
		return this.httpClient.get(`${environment.workOrderApiBaseUrl}/tickets/download-template`, { responseType: 'blob' });
	}

	uploadTicket(formData: any): Observable<TicketUploadResultDto> {
		return this.httpClient.post(`${environment.workOrderApiBaseUrl}/tickets/upload`, formData) as
			Observable<TicketUploadResultDto>;
	}
	uploadTicket_Queue(formData: any): Observable<TicketUploadResultDto> {
		return this.httpClient.post(`${environment.workOrderApiBaseUrl}/tickets/upload-queue`, formData) as
			Observable<TicketUploadResultDto>;
	}
}
