<lib-navbar-dropdown [state]="state" title="System Settings">

  <button class="action" routerLink="/users/settings/user-management">

    <mat-icon fontSet="material-symbols-outlined">manage_accounts</mat-icon>
    <span>Users</span>

  </button>

  <button class="action" routerLink="/users/settings/manage-roles">

    <mat-icon fontSet="material-symbols-outlined">supervisor_account</mat-icon>
    <span>Roles</span>

  </button>

  <button class="action" routerLink="/general/settings">

    <mat-icon fontSet="material-symbols-outlined">settings</mat-icon>
    <span>General</span>

  </button>

  <button class="action" routerLink="/catalog/settings">

    <mat-icon fontSet="material-symbols-outlined">menu_book</mat-icon>
    <span>Catalogs</span>

  </button>

</lib-navbar-dropdown>
