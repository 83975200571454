import { NgModule } from '@angular/core';
import { ArrowLeftModule, ModelBuilderModule } from '@carbon/icons-angular';
import {
  SideNavModule,
  HeaderModule,
  InputModule,
  CheckboxModule,
  ButtonModule,
  AccordionModule,
  TabsModule,
  DropdownModule,
  GridModule,
  ProgressIndicatorModule,
  RadioModule,
  SearchModule,
  SelectModule,
  DialogModule,
  PlaceholderModule,
  TableModule,
  LoadingModule,
  NFormsModule,
  TilesModule,
  ContentSwitcherModule,
  NotificationModule,
  FileUploaderModule,
  DatePickerModule,
  ComboBoxModule,
  ModalModule,
  UIShellModule,
  IconModule,
  PaginationModule,
  SliderModule,
  InlineLoadingModule,
  SkeletonModule,
} from "carbon-components-angular";


@NgModule({
  declarations: [],
  imports: [
    SideNavModule,
    HeaderModule,
    InputModule,
    CheckboxModule,
    ButtonModule,
    AccordionModule,
    TabsModule,
    DropdownModule,
    GridModule,
    ProgressIndicatorModule,
    RadioModule,
    SearchModule,
    SelectModule,
    DialogModule,
    PlaceholderModule,
    TableModule,
    LoadingModule,
    NFormsModule,
    TilesModule,
    ContentSwitcherModule,
    NotificationModule,
    FileUploaderModule,
    DatePickerModule,
    ComboBoxModule,
    ModalModule,
    UIShellModule,
    IconModule,
    PaginationModule,
    TableModule,
    SliderModule,
    InlineLoadingModule,
    ModelBuilderModule,
    SkeletonModule
  ],
  exports: [
    SideNavModule,
    HeaderModule,
    InputModule,
    CheckboxModule,
    ButtonModule,
    AccordionModule,
    TabsModule,
    DropdownModule,
    GridModule,
    ProgressIndicatorModule,
    RadioModule,
    SearchModule,
    SelectModule,
    DialogModule,
    PlaceholderModule,
    TableModule,
    LoadingModule,
    NFormsModule,
    TilesModule,
    ContentSwitcherModule,
    NotificationModule,
    FileUploaderModule,
    DatePickerModule,
    ComboBoxModule,
    ModalModule,
    UIShellModule,
    IconModule,
    PaginationModule,
    SliderModule,
    InlineLoadingModule,
    ArrowLeftModule,
    ModelBuilderModule,
    SkeletonModule
  ]
})
export class CarbonModule { }
