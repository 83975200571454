import {Component, OnDestroy, OnInit} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {catchError, debounceTime, distinctUntilChanged, EMPTY, filter, Subject, switchMap, takeUntil, tap} from "rxjs";
import {GeneralChatService} from "../../../../services/general-chat/general-chat.service";
import {LoadingState} from "../../../../state/loading-state.enum";
import {CitationModel} from "../../../../services/general-chat/models/citation.model";
import {ChatHistoryModel} from "../../../../services/general-chat/models/chat-history.model";

@Component({
  selector: 'app-global-callout',
  templateUrl: './global-callout.component.html',
  styleUrls: ['./global-callout.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({
        opacity: 1,
      })),
      state('hidden', style({
        opacity: 0,
      })),
      transition('shown => hidden', animate('150ms')),
      transition('hidden => shown', animate('150ms')),
    ]),
    trigger('modalSlide', [
      state('max', style({
        transform: 'translateY(0)',
        opacity: 1,
      })),
      state('min', style({
        transform: 'translateY(-5%)',
        opacity: .75,
      })),
      transition('max => min', animate('100ms')),
      transition('min => max', animate('100ms')),
    ])
  ]
})
export class GlobalCalloutComponent implements OnInit, OnDestroy {
  constructor(private generalChatService: GeneralChatService) {
  }

  state: LoadingState = LoadingState.loaded;
  loadingStates = LoadingState;

  isVisible: boolean = false;

  componentDestroyed$ = new Subject<void>();

  query$ = new Subject<string>();

  queryFieldValue: string = '';

  // Query results:
  summary: string | null = null;
  citations: CitationModel[] = [];
  deepLinks: CitationModel[] = [];

  showSuggestions = false;

  chatHistory: ChatHistoryModel[] = [];

  ngOnInit(): void {
    this.query$.pipe(
      debounceTime(750),
      distinctUntilChanged(),
      filter(query => query.length > 0),
      tap(() => this.state = LoadingState.loading),
      switchMap(query => this.generalChatService.query(query, this.chatHistory)),
      takeUntil(this.componentDestroyed$),
      catchError(() => {
        this.state = LoadingState.error;

        return EMPTY;
      }),
    ).subscribe(result => {
      this.state = LoadingState.loaded;

      this.chatHistory.push({
        role: 'user',
        userQuery: this.queryFieldValue,
      });
      this.chatHistory.push({
        role: 'assistant',
        userQuery: result.summary,
      });

      this.summary = result.summary;
      this.citations = result.citations;
      this.deepLinks = result.deepLinks;
    })
  }

  queryFieldChange(): void {
    this.query$.next(this.queryFieldValue);
  }

  removeModalWrapper = true;

  animationComplete(): void {
    if (!this.isVisible) {
      this.removeModalWrapper = true;
    }
  }

  toggleVisibility() {
    if (!this.isVisible) {
      this.removeModalWrapper = false;
    }

    this.isVisible = !this.isVisible;
  }

  suggestionClicked(suggestion: string): void {
    this.queryFieldValue = suggestion;
    this.queryFieldChange();

    this.showSuggestions = false;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
