<button (click)="click()" [ngClass]="{'selected': (context.guidSelected$ | async) === data.guid}">
  <div class="text-wrapper">

    <div *ngIf="data.item.heading" class="heading">
      {{ data.item.heading }}
    </div>

    <div class="label">
      {{ data.item.label }}
    </div>

  </div>

  <mat-icon fontSet="material-symbols-outlined">chevron_right</mat-icon>
</button>
