import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';
import {SiteLayoutComponent} from './core/layout/site-layout.component';
import {Permission} from './services/utility/permission-constant';
import {LoginComponent} from './views/login/login.component';
import {ProfileComponent} from './views/profile/profile.component';
import {UnauthorizedComponent} from './views/unauthorized/unauthorized.component';
import {PublicComponent} from "./public/public.component";
import {NotFoundComponent} from "./views/not-found/not-found.component";

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'public',
    component: PublicComponent,
    loadChildren: () => import('./public/public.module').then(c => c.PublicModule)
  },
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      {path: '', redirectTo: 'dashboards/task-dashboard', pathMatch: 'full'},
      {
        path: 'profile',
        children:
          [
            {
              path: '',
              component: ProfileComponent,
            }
          ]

      },
      {
        path: 'clients',
        loadChildren: () => import('./views/client/client.module').then(c => c.ClientModule),
        canActivate: [AuthGuard],
        data: {permission: Permission.ViewClient, sidebarToken: 'clients'}
      },
      {
        path: 'vendors',
        loadChildren: () => import('./views/vendor/vendor.module').then(c => c.VendorModule),
        canActivate: [AuthGuard],
        data: {permission: Permission.ViewVendor, sidebarToken: 'vendors'}
      },
      {
        path: 'sales/deal',
        loadChildren: () => import('./views/deal/deal.module').then(c => c.DealModule),
        canActivate: [AuthGuard],
        data: {permission: Permission.ViewDeal, sidebarToken: 'opportunities'}
      },
      {
        path: 'sales/project',
        loadChildren: () => import('./views/project/prospects.module').then(c => c.ProspectsModule),
      },
      {
        path: 'delivery',
        loadChildren: () => import('./views/contract/contract-detail.module').then(c => c.ContractDetailModule),
        canActivate: [AuthGuard],
        data: {permission: Permission.ViewDelivery, sidebarToken: 'delivery'}
      },
      {
        path: 'contracts/:contractId/tickets/:ticketId',
        loadChildren: () => import('./views/ticket/ticket.module').then(c => c.TicketModule),
        data: {sidebarToken: 'ticket'}
      },
      {
        path: 'catalog/settings',
        loadChildren: () => import('./views/settings/settings.module').then(c => c.SettingsModule)
      },
      {
        path: 'users/settings',
        loadChildren: () => import('./views/settings/settings.module').then(c => c.SettingsModule),
      },
      {
        path: 'general/settings',
        loadChildren: () => import('./views/settings/settings.module').then(c => c.SettingsModule)
      },
      {
        path: 'usernotes',
        loadChildren: () => import('./views/usernote/usernote.module').then(c => c.UsernoteModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/report.module').then(c => c.ReportModule)
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(c => c.DashboardModule)
      },
      {
        path: 'technicians',
        loadChildren: () => import('./views/technicians/technicians.module').then(c => c.TechniciansModule),
        canActivate: [AuthGuard],
        data: {permission: Permission.ViewVendorTechnicians, sidebarToken: 'technicians'}
      },
      {
        path: 'identity',
        loadChildren: () => import('./views/identity/identity.module').then(c => c.IdentityModule),
      },
      {
        path: 'deletetestdata',
        loadChildren: () => import('./views/deletetestdata/deletetestdata.module').then(c => c.DeleteTestDataModule)
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
