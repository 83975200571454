import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureFlagsService} from "../../../services/feature-flags/feature-flags.service";
import {Subject, takeUntil} from "rxjs";

@Directive({
  selector: '[useFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit, OnDestroy {

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private flags: FeatureFlagsService) {
  }

  @Input() useFeatureFlag: string;
  @Input() useFeatureFlagDefaultValue: boolean = false;
  @Input() useFeatureFlagElse?: TemplateRef<any> | null;
  @Input() useFeatureFlagInvert: boolean = false;

  destroy$ = new Subject<void>();

  ngOnInit() {
    if (this.useFeatureFlagDefaultValue == null) {
      throw new Error('Default value is required');
    }

    this.flags.variation(this.useFeatureFlag, this.useFeatureFlagDefaultValue).pipe(
      takeUntil(this.destroy$),
    ).subscribe((variation) => {
      if (this.viewContainer.length > 0) {
        this.viewContainer.clear();
      }

      const variationAfterInversion = this.useFeatureFlagInvert ? !variation : variation;
      if (variationAfterInversion) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        if (this.useFeatureFlagElse != null) {
          this.viewContainer.createEmbeddedView(this.useFeatureFlagElse);
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
