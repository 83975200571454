import {LogLevel, Configuration, BrowserCacheLocation} from '@azure/msal-browser';
import {environment} from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const b2cPolicies = {
  names: {
    signUpSignIn: environment.signUpSignInB2cPolicy,
    editProfile: environment.editProfileB2cPolicy
  },
  authorities: {
    signUpSignIn: {
      authority: `${environment.b2cAuthority}${environment.signUpSignInB2cPolicy}`,
    },
    editProfile: {
      authority: `${environment.b2cAuthority}${environment.editProfileB2cPolicy}`
    }
  },
  authorityDomain: environment.authorityDomain
};

export function getMsalConfig(): Configuration {
  return {
    auth: {
      clientId: environment.b2cClientId,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: getRedirectUriFromHost(),
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      loggerOptions: {
        loggerCallback: (logLevel, message, containsPii) => {
          // Implement logging logic if needed
        },
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  };
}

function getRedirectUriFromHost(): string {
  const host = window.location.host;
  const whitelistedUris: string[] = environment.b2cRedirectUris;
  const redirectUri = whitelistedUris.find((uri: string) => uri.includes(host));

  if (redirectUri) {
    return redirectUri;
  }

  return environment.fallbackB2cRedirectUri;
}
