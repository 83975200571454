import {Component, HostListener, Input, OnInit} from '@angular/core';
import {CitationModel} from "../../../../../../services/general-chat/models/citation.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-citation',
  templateUrl: './citation.component.html',
  styleUrls: ['./citation.component.css']
})
export class CitationComponent implements OnInit {

  constructor(private router: Router) {
  }

  @Input() citation: CitationModel;
  @Input() isDeepLink: boolean = false;

  ngOnInit(): void {
  }

  @HostListener('click')
  navigateToCitation(): void {
    if (this.isDeepLink) {
      this.router.navigate([this.citation.url]);
      return;
    }

    window.open(this.citation.url);
  }

}
