<div class="wrapper" [ngStyle]="{'display': removeModalWrapper ? 'none' : 'flex'}">

  <div class="global-search-modal" [@modalSlide]="isVisible ? 'max' : 'min'">

    <div class="modal-header">

      <div class="search-wrapper">
        <mat-icon fontSet="material-symbols-outlined">search</mat-icon>
        <input type="text" placeholder="How can I help?" [(ngModel)]="queryFieldValue" (input)="queryFieldChange()">
      </div>

      <div class="action-wrapper">
        <mat-icon (click)="showSuggestions = !showSuggestions" class="close-modal" fontSet="material-symbols-outlined">
          question_mark
        </mat-icon>
        <mat-icon class="close-modal" fontSet="material-symbols-outlined" (click)="toggleVisibility()">close</mat-icon>
      </div>

    </div>

    <div class="global-modal-content" *ngIf="!showSuggestions" [ngSwitch]="state">

      <ng-container *ngSwitchCase="loadingStates.loaded">

        <ng-container *ngIf="summary != null; else placeholder">

          <fieldflow-section-card title="Query Results" icon="neurology">
            <p class="output">
              {{ summary }}
            </p>

            <fieldflow-banner color="#F5963A" title="Content Warning" icon="warning">
              AI generated content may include inaccuracies or misinformation. Always use your best judgement when
              making
              decisions based on generated content.
            </fieldflow-banner>

          </fieldflow-section-card>

          <fieldflow-section-card *ngIf="deepLinks.length > 0" title="Results" icon="search">
            <app-citation *ngFor="let citation of deepLinks" [citation]="citation" [isDeepLink]="true"></app-citation>
          </fieldflow-section-card>

          <fieldflow-section-card *ngIf="citations.length > 0" title="Sources" icon="description">
            <app-citation *ngFor="let citation of citations" [citation]="citation"></app-citation>
          </fieldflow-section-card>

        </ng-container>

        <ng-template #placeholder>
          <div class="query-placeholder">
            Ask a question, for a document, for help, and more.
          </div>
        </ng-template>

      </ng-container>

      <div *ngSwitchCase="loadingStates.loading" class="loading-wrapper">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>

      <div *ngSwitchCase="loadingStates.error">
        Something went wrong...
      </div>

    </div>

    <div *ngIf="showSuggestions">
      <app-suggestion suggestion="Who are you?" (clicked)="suggestionClicked($event)"></app-suggestion>
      <app-suggestion suggestion="Please give me an example of some things I can ask you." (clicked)="suggestionClicked($event)"></app-suggestion>
      <app-suggestion suggestion="My Windows 365 cloud pc is running slowly. Please help." (clicked)="suggestionClicked($event)"></app-suggestion>
      <app-suggestion suggestion="Please write a detailed summary of the current state of ticket FPPQ-99981 that I can send to the client." (clicked)="suggestionClicked($event)"></app-suggestion>
      <app-suggestion suggestion="Can you please give me information on purchase order 91941?" (clicked)="suggestionClicked($event)"></app-suggestion>
      <app-suggestion suggestion="Please give me detailed information about the client Sandbox VR, Inc." (clicked)="suggestionClicked($event)"></app-suggestion>
      <app-suggestion suggestion="Given the weather forecast for the next 5 days in Paramus, NJ, are there any tickets I should be concerned about in the next 5 days?" (clicked)="suggestionClicked($event)"></app-suggestion>
      <app-suggestion suggestion="Please give me some technicians you would recommend within 25 miles proximity of Paramus, NJ. Let me know the total number of technicians available. Give me specific reasons for recommendations" (clicked)="suggestionClicked($event)"></app-suggestion>
    </div>

  </div>

  <div class="shader" [@visibilityChanged]="isVisible ? 'shown' : 'hidden'"
       (@visibilityChanged.done)="animationComplete()" (click)="toggleVisibility()"></div>

</div>
