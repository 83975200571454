import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingHandlerComponent} from './loading-handler.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    LoadingHandlerComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  exports: [
    LoadingHandlerComponent
  ]
})
export class LoadingHandlerModule {
}
