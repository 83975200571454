<button>

  <div class="dropdown-wrapper">

    <div class="name">
      {{ name }}
    </div>

    <div class="role">
      {{ role }}
    </div>

  </div>

  <mat-icon fontSet="material-symbols-outlined">keyboard_arrow_down</mat-icon>

  <div class="badge">
    {{ initials }}
  </div>

</button>
