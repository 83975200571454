import {Injectable} from "@angular/core";
import {SignalrServiceWrapper} from "../../../shared/common/signalr-service-wrapper";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RealTimeStatusService extends SignalrServiceWrapper {

  constructor() {
    super(`${environment.milestoneApiBaseUrl}/realTimeStatusHub`);
  }
  
}
