import {Injectable} from "@angular/core";
import {TechnicianStateModel} from "./models/technician-state.model";
import {Action, createSelector, Selector, State, StateContext} from "@ngxs/store";
import {TechnicianService} from "../../services/technician/technician/technician.service";
import {LoadingState} from "../loading-state.enum";
import {catchError, EMPTY, tap} from "rxjs";

export class GetTechnician {
  static readonly type = '[Technician] Get Technician';

  constructor(public technicianId: number) {
  }
}

@State<TechnicianStateModel[]>({
  name: 'technician',
  defaults: []
})
@Injectable({
  providedIn: 'root'
})
export class TechnicianState {

  constructor(private technicianService: TechnicianService) {
  }

  @Selector()
  public static getTechnician(technicianId: number) {
    return createSelector([TechnicianState], (state) => {
      return state.technician.find(technicianState => technicianState.technicianId === technicianId);
    });
  }

  @Action(GetTechnician)
  getTechnician(ctx: StateContext<TechnicianStateModel[]>, action: GetTechnician) {
    let currentState = ctx.getState();

    if (currentState.some(technician => technician.technicianId === action.technicianId)) {
      return;
    }

    ctx.setState([...currentState, {
      loadingState: LoadingState.loading,
      technicianId: action.technicianId,
    }]);

    return this.technicianService.getListTechnician(action.technicianId).pipe(
      tap(technician => {
        currentState = ctx.getState();

        ctx.setState(currentState.map(technicianState => {
          if (technicianState.technicianId === action.technicianId) {
            return {
              ...technicianState,
              loadingState: LoadingState.loaded,
              technician
            };
          }

          return technicianState;
        }));
      }),
      catchError(() => {
        currentState = ctx.getState();

        ctx.setState(currentState.map(technicianState => {
          if (technicianState.technicianId === action.technicianId) {
            return {
              ...technicianState,
              loadingState: LoadingState.error,
            };
          }

          return technicianState;
        }));

        return EMPTY;
      })
    );
  }

}
