import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {UserService} from 'src/app/services/user/user.service';
import {UtilityService} from 'src/app/services/utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private utility: UtilityService, private router: Router, private toastr: ToastrService, private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const includesCoreApplicationType = this.utility.applicationAccessEnabled();
    if (!includesCoreApplicationType) {
      this.userService.logout();
      this.router.navigate(['/login']);

      return false;
    }

    if (this.utility.hasPermission(route.data.permission))
      return true;
    else {
      this.router.navigate(['/unauthorized'], {queryParams: {returnUrl: state.url}});
      return false;
    }
  }
}
