import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fieldflow-banner',
  templateUrl: './field-flow-banner.component.html',
  styleUrls: ['./field-flow-banner.component.css']
})
export class FieldFlowBannerComponent implements OnInit {

  constructor() {
  }

  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() color: string = '';

  ngOnInit(): void {
  }

}
