import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, map, Observable, Subject, take, takeUntil} from "rxjs";
import {
  InfiniteScrollConfigurationModel
} from "../../../../../../shared/components/infinite-scroll-container/models/infinite-scroll-configuration.model";
import {LabeledDataModel} from "../scroll-item/models/labeled-data.model";
import {ScrollItemComponent} from "../scroll-item/scroll-item.component";
import {Contract} from "../../../../../../models/proposals/proposal";
import {
  ProjectActivityResourceService
} from "../../../../../../services/project-activity-resources/project-activity-resources.service";
import {
  DeliverySearchState,
  UpdateDeliverySearchContract
} from "../../../../../../state/delivery-search/delivery-search.state";
import {Select, Store} from "@ngxs/store";
import {
  InfiniteScrollItemModel
} from "../../../../../../shared/components/infinite-scroll-container/models/infinite-scroll-item.model";
import {DeliverySearchStateModel} from "../../../../../../state/delivery-search/models/delivery-search.state-model";
import {DeliverySearchCategoryEnum} from "../scroll-item/enums/delivery-search-category.enum";

@Component({
  selector: 'app-contract-search',
  templateUrl: './contract-search.component.html',
  styleUrls: ['./contract-search.component.css']
})
export class ContractSearchComponent implements OnInit, OnDestroy {

  selection$ = new BehaviorSubject<string | null>(null);

  @Input() clientId: number;

  initialValues: { items: InfiniteScrollItemModel<Contract>[], totalRecords: number } | null = null;

  @Select(DeliverySearchState) deliverySearchState$: Observable<DeliverySearchStateModel>;

  componentDestroyed$ = new Subject<void>();

  configuration: InfiniteScrollConfigurationModel<LabeledDataModel<Contract>> = {
    pageSize: 20,
    loadCallback: (pagination, query) => {
      return this.projectActivityResourcesService.getClientContractsByClientId(query.clientId, true, pagination.page, pagination.pageSize).pipe(
        map((response) => {
          return {
            records: (response.results as any[]).map((contract) => {
              return {
                item: {
                  label: contract.title,
                  heading: contract.contractCode,
                  category: DeliverySearchCategoryEnum.contract,
                  data: contract
                },
                guid: contract.id.toString()
              }
            }),
            totalRecords: response.total!
          }
        })
      )
    },
    itemComponent: ScrollItemComponent,
    context: {
      guidSelected$: this.selection$
    }
  }

  constructor(private store: Store, private projectActivityResourcesService: ProjectActivityResourceService) {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    // Initialize selection with the active record guid
    this.deliverySearchState$.pipe(
      take(1),
      takeUntil(this.componentDestroyed$)
    ).subscribe((state) => {
      if (state.contracts?.activeRecordGuid) {
        this.selection$.next(state.contracts.activeRecordGuid);
      }

      if (state.contracts?.items) {
        this.initialValues = {
          items: state.contracts.items,
          totalRecords: state.contracts.totalRecords!
        };
      }
    });

    this.selection$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((guid) => {
      this.store.dispatch(new UpdateDeliverySearchContract({
        activeRecordGuid: guid
      }));
    });
  }

  cacheState(state: { items: InfiniteScrollItemModel<unknown>[], totalRecords: number }): void {
    this.store.dispatch(new UpdateDeliverySearchContract({
      items: state.items as InfiniteScrollItemModel<Contract>[],
      totalRecords: state.totalRecords
    }));
  }

}
