<div class="child-header custom-child-header d-flex align-items-center">
  <h1 class="custom-h1"> My Profiles </h1>
</div>
<div>
  <h1> My Profile </h1>
  <div class="w-70">
    <p><strong>First Name: </strong> {{profile?.givenName}}</p>
    <p><strong>Last Name: </strong> {{profile?.surname}}</p>
    <p><strong>Email: </strong> {{profile?.mail}}</p>
    <p><strong>Id: </strong> {{profile?.id}}</p>
</div>
</div>
