import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalCalloutComponent} from "./global-callout.component";
import {MatIconModule} from "@angular/material/icon";
import {
  FieldFlowSectionCardModule
} from "../../../../../../projects/fieldflow-ui/src/lib/components/field-flow-section-card/field-flow-section-card.module";
import {
  FieldFlowBannerModule
} from "../../../../../../projects/fieldflow-ui/src/lib/components/field-flow-banner/field-flow-banner.module";
import {CitationComponent} from './components/citation/citation.component';
import {FormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { SuggestionComponent } from './components/suggestion/suggestion.component';


@NgModule({
  declarations: [
    GlobalCalloutComponent,
    CitationComponent,
    SuggestionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    FieldFlowSectionCardModule,
    FieldFlowBannerModule,
    MatProgressSpinnerModule
  ],
  exports: [
    GlobalCalloutComponent
  ]
})
export class GlobalCalloutModule {
}
