import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {IGetMilestoneModel} from "./models/get-milestone.model";

@Injectable({
  providedIn: 'root'
})
export class MilestoneService {

  constructor(private http: HttpClient) {
  }

  getAllMilestones(): Observable<IGetMilestoneModel[]> {
    return this.http.get<IGetMilestoneModel[]>(`${environment.milestoneApiBaseUrl}/milestone`);
  }

}
