import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  @Input() name: string;
  @Input() role: string;

  constructor() {
  }

  get initials() {
    const nameComponents = this.name.split(/[ -]/);
    // Take the first and last name components.
    if (nameComponents.length > 1) {
      return nameComponents[0][0] + nameComponents[nameComponents.length - 1][0];
    }

    // If there is only one name element, take the first two characters.
    return nameComponents[0].substring(0, 2);
  }

  ngOnInit(): void {
  }

}
