import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldFlowPrimaryLayoutComponent} from './field-flow-primary-layout.component';
import {FieldFlowNavbarModule} from "../field-flow-navbar/field-flow-navbar.module";
import {FieldFlowSidebarModule} from "../field-flow-sidebar/field-flow-sidebar.module";


@NgModule({
  declarations: [
    FieldFlowPrimaryLayoutComponent
  ],
  imports: [
    CommonModule,
    FieldFlowNavbarModule,
    FieldFlowSidebarModule
  ],
  exports: [
    FieldFlowPrimaryLayoutComponent
  ]
})
export class FieldFlowPrimaryLayoutModule {
}
