<form [formGroup]="filterFormGroup">
  <div class="wrapper" *ngFor="let group of filterGroups">
    <div class="row-label" *ngIf="group.label != null">{{ group.label }}</div>

    <div class="input-grouping">
      <ng-container *ngFor="let element of group.items">
        <input *ngIf="parseDropdownType(element) === optionTypes.text" [placeholder]="element.label" [formControlName]="element.key">
        
        <div class="slider-layout" *ngIf="parseDropdownType(element) === optionTypes.range">
          <label>{{ element.label }}</label>
          <div>
            <mat-slider class="custom-slider" 
              min="0" max="1000" thumbLabel [formControlName]="element.key">
            </mat-slider>
          </div>
        </div>

        <ng-select [placeholder]="element.label" [multiple]="convertFromSuperToDropdown(element).enableMultiSelect ?? false" *ngIf="parseDropdownType(element) === optionTypes.dropdown" [loading]="dropdownFilterOptionsBuffer.get(element.key) == null" [items]="dropdownFilterOptionsBuffer.get(element.key) ?? null" bindLabel="label" bindValue="index" [formControlName]="element.key"></ng-select>
      </ng-container>
    </div>
  </div>
</form>
