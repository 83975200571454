import {Injectable} from "@angular/core";
import {Action, createSelector, Selector, State, StateContext} from "@ngxs/store";
import {LoadingState} from "../loading-state.enum";
import {catchError, EMPTY, tap} from "rxjs";
import {TeamStateModel} from "./models/team-state.model";
import {TeamService} from "../../services/teams/team.service";

export class GetTeam {
  static readonly type = '[Team] Get Team';

  constructor(public teamId: number) {
  }
}

@State<TeamStateModel[]>({
  name: 'team',
  defaults: []
})
@Injectable({
  providedIn: 'root'
})
export class TeamState {

  constructor(private teamService: TeamService) {
  }

  @Selector()
  public static getTeam(teamId: number) {
    return createSelector([TeamState], (state) => {
      return state.team.find(teamState => teamState.teamId === teamId);
    });
  }

  @Action(GetTeam)
  getTechnician(ctx: StateContext<TeamStateModel[]>, action: GetTeam) {
    let currentState = ctx.getState();

    if (currentState.some((team) => team.teamId === action.teamId)) {
      return;
    }

    ctx.setState([...currentState, {
      loadingState: LoadingState.loading,
      teamId: action.teamId,
    }]);

    return this.teamService.getTeamById(action.teamId).pipe(
      tap(team => {
        currentState = ctx.getState();

        ctx.setState(currentState.map(teamState => {
          if (teamState.teamId === action.teamId) {
            return {
              ...teamState,
              loadingState: LoadingState.loaded,
              team: team.results
            };
          }

          return teamState;
        }));
      }),
      catchError(() => {
        currentState = ctx.getState();

        ctx.setState(currentState.map(teamState => {
          if (teamState.teamId === action.teamId) {
            return {
              ...teamState,
              loadingState: LoadingState.error,
            };
          }

          return teamState;
        }));

        return EMPTY;
      })
    );
  }

}
