import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-settings-dropdown',
  templateUrl: './settings-dropdown.component.html',
  styleUrls: ['./settings-dropdown.component.css']
})
export class SettingsDropdownComponent implements OnInit {

  @Input() state!: 'fadeOut' | 'fadeIn';

  constructor() {
  }

  ngOnInit(): void {
  }

}
