import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Region } from 'src/app/models/region/region';
import { TableFilters } from 'src/app/shared/common/tableFilters';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private httpClient: HttpClient) { }

  createRegion(obj: Region): Observable<any> {
    return this.httpClient.post(`${environment.configurationsApiBaseUrl}/regions`, obj);
  }

  updateRegion(obj: Region): Observable<any> {
    return this.httpClient.put(`${environment.configurationsApiBaseUrl}/regions`, obj);
  }

  deleteRegion(id: number | undefined): Observable<any> {
    return this.httpClient.delete(`${environment.configurationsApiBaseUrl}/regions/${id}`);
  }

  getRegionById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.configurationsApiBaseUrl}/regions/${id}`);
  }

  getPagedRegions(payload: TableFilters): Observable<any> {
    return this.httpClient.get<any>(`${environment.configurationsApiBaseUrl}/regions?sort=${payload.sort}&search=${payload.search}&page=${payload.page}&pageSize=${payload.pageSize}&isAscending=${payload.isAscending}`);
  }
}