import {Injectable} from "@angular/core";
import {Action, createSelector, Selector, State, StateContext} from "@ngxs/store";
import {VendorStateModel} from "./models/vendor-state.model";
import {VendorService} from "../../services/vendor/vendor.service";
import {catchError, EMPTY, tap} from "rxjs";
import {LoadingState} from "../loading-state.enum";

export class GetVendorsByIds {
  static readonly type = '[Vendors] Get Vendor By Id';

  constructor(public vendorIds: number[]) {
  }
}

@State<VendorStateModel[]>({
  name: 'vendors',
  defaults: []
})
@Injectable({
  providedIn: 'root'
})
export class VendorsState {
  constructor(private vendorService: VendorService) {
  }

  @Selector()
  public static getVendor(vendorId: number) {
    return createSelector([VendorsState], (state) => {
      return state.vendors.find(vendor => vendor.vendorId === vendorId);
    });
  }

  @Action(GetVendorsByIds)
  getVendorById(ctx: StateContext<VendorStateModel[]>, action: GetVendorsByIds) {
    let currentState = ctx.getState();

    let vendorIdsTargetedByRequest: number[] = [];
    for (let vendorId of action.vendorIds) {
      if (currentState.some(vendor => vendor.vendorId === vendorId)) {
        continue;
      }

      vendorIdsTargetedByRequest.push(vendorId);

      ctx.setState([...currentState, {
        loadingState: LoadingState.loading,
        vendorId: vendorId,
      }]);
    }

    if (vendorIdsTargetedByRequest.length === 0) {
      return;
    }

    return this.vendorService.getVendorByIds(vendorIdsTargetedByRequest).pipe(
      tap(vendors => {
        currentState = ctx.getState();
        const res = currentState.map<VendorStateModel>(x => {
          if (vendorIdsTargetedByRequest.includes(x.vendorId)) {
            return {
              ...x,
              loadingState: LoadingState.loaded,
              vendor: vendors.find(vendor => vendor.id === x.vendorId)
            };
          }

          return x;
        });
        ctx.setState(res);
      }),
      catchError(() => {
        currentState = ctx.getState();
        ctx.setState(currentState.map(x => {
          if (vendorIdsTargetedByRequest.includes(x.vendorId)) {
            return {
              ...x,
              loadingState: LoadingState.error,
            };
          }

          return x;
        }));

        return EMPTY;
      })
    );
  }

}
