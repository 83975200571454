import {Component, Input, OnInit} from '@angular/core';
import {LoadingState} from "../../../state/loading-state.enum";

@Component({
  selector: 'd1-loading-handler',
  templateUrl: './loading-handler.component.html',
  styleUrls: ['./loading-handler.component.css']
})
export class LoadingHandlerComponent implements OnInit {

  constructor() {
  }

  @Input() state: LoadingState = LoadingState.loading;
  loadingStates = LoadingState;

  ngOnInit(): void {
  }

}
