import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity, ActivityUpdateDto } from 'src/app/models/activity/activity';
import { ActivityCategory } from 'src/app/models/activity/activity-category';
import { ActivityStatus } from 'src/app/models/activity/activity-status';
import { ActivityType } from 'src/app/models/activity/activity-type';
import { Currency } from 'src/app/models/activity/currency';
import { ResponsePayloadDTO } from 'src/app/models/salesAPIResponse';
import { environment } from 'src/environments/environment';
import { map } from "rxjs/operators";
import { ProposalService } from '../proposal/proposal.service';
import { TableFilters } from 'src/app/shared/common/tableFilters';

@Injectable({
	providedIn: 'root',
})
export class ActivityService {
	constructor(private httpClient: HttpClient, private proposalService: ProposalService) { }

	getContractActivityById(id: number): Observable<ResponsePayloadDTO<Activity>> {
		return this.httpClient.get<ResponsePayloadDTO<Activity>>(
			`${environment.projectApiBaseUrl}/contract-activities/` + id
		).pipe(
			// Again, this should be done at the API level in the future
			map(res => {
				const activityResources = res.results.resources?.map(r => {
					return {
						...r,
						isVariable: r.categoryName !== 'Project Management',
					};
				});

				return {
					...res,
					results: {
						...res.results,
						activityResources,
					}
				};
			}),
		);;
	}

	getActivityById(id: number): Observable<ResponsePayloadDTO<Activity>> {
		return this.httpClient.get<ResponsePayloadDTO<Activity>>(
			`${environment.configurationsApiBaseUrl}/activities/activity/${id}`
		).pipe(
			// Again, this should be done at the API level in the future
			map(res => {
				const activityResources = res.results.activityResources?.map(r => {
					return {
						...r,
						isVariable: r.categoryName !== 'Project Management',
					};
				});

				return {
					...res,
					results: {
						...res.results,
						activityResources,
					}
				};
			}),
		);
	}

	getAllGlobelActivities(showApprovedOnly = false): Observable<ResponsePayloadDTO<Activity[]>> {
		var res = this.httpClient.get<ResponsePayloadDTO<Activity[]>>(
			`${environment.configurationsApiBaseUrl}/activities?showApprovedOnly=${showApprovedOnly}`
		);
		return res;
	}

	getCurrencies(): Observable<ResponsePayloadDTO<Currency[]>> {
		return this.httpClient.get<ResponsePayloadDTO<Currency[]>>(
			`${environment.configurationsApiBaseUrl}/currencies`
		);
	}

	updateActivity(activity: Activity): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.put<ResponsePayloadDTO<boolean>>(
			`${environment.projectApiBaseUrl}/contract-activities`,
			activity
		);
	}

	getActivityCategories(): Observable<ResponsePayloadDTO<ActivityCategory[]>> {
		return this.httpClient.get<ResponsePayloadDTO<ActivityCategory[]>>(
			`${environment.configurationsApiBaseUrl}/activitycatagories`
		);
	}

	saveContractActivity(contractId: number, activity: ActivityUpdateDto): Observable<ResponsePayloadDTO<Activity>> {
		return this.httpClient.post<ResponsePayloadDTO<Activity>>(
			`${environment.projectApiBaseUrl}/contract-activities/${contractId}`,
			activity,
		);
	}

	getActivityStatuses(): Observable<ResponsePayloadDTO<ActivityStatus[]>> {
		return this.httpClient.get<ResponsePayloadDTO<ActivityStatus[]>>(
			`${environment.configurationsApiBaseUrl}/activity-statuses`
		);
	}

	/**
	 * @deprecated
	 * @param activity
	 */
	saveActivity(activity: Activity): Observable<ResponsePayloadDTO<number>> {
		return this.httpClient.post<ResponsePayloadDTO<number>>(
			`${environment.projectApiBaseUrl}/contract-activities`,
			activity
		);
	}

	saveOrUpdateActivity(activity: ActivityUpdateDto): Observable<ResponsePayloadDTO<Activity>> {
		return this.httpClient.post<ResponsePayloadDTO<Activity>>(
			`${environment.configurationsApiBaseUrl}/activities`,
			activity
		);
	}

	deleteActivityByActivityId(
		id: number
	): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.projectApiBaseUrl}/contract-activities/${id}`
		);
	}
	getActivityTypes(): Observable<ResponsePayloadDTO<ActivityType[]>> {
		return this.httpClient.get<ResponsePayloadDTO<ActivityType[]>>(`${environment.configurationsApiBaseUrl}/activities/types`);
	}

	saveActivities(contractId: number, activities: number[]): Observable<void> {
		return this.httpClient.post<void>(
			`${environment.projectApiBaseUrl}/contract-activities/${contractId}/multiple`,
			activities
		);
	}

	hasSomethingChangedInActivitiesList(event: any, activityCurrentState: Activity, targetActvityId: number, contractId: number): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			this.proposalService.getProposalActivitiesByProposalId(contractId, false, new TableFilters()).subscribe({
				next: (res) => {
					let activitiesBackEnd = res.results;

					let backendItem = activitiesBackEnd.find((x: any) => x.id == targetActvityId);
					let frontendItem = activityCurrentState;

					let displayNamesFromA = activitiesBackEnd.map((x) => x.displayName);
					let displayNamesFromB = Array.from(document.getElementsByClassName('displayname-input')).map((x: any) => x.value);
					let displayNamesFromAString = displayNamesFromA.join(',');
					let displayNamesFromBString = displayNamesFromB.join(',');

					let isDisplayNameChanged = displayNamesFromAString != displayNamesFromBString;

					observer.next(isDisplayNameChanged);
					observer.complete();
				},
				error: (e) => {
					// Error handling
					observer.error(e);
				},
				complete: () => {
					// Completion handling
					observer.complete();
				}
			});
		});
	}
}