<ng-container *ngIf="loadingState === LoadingState.loaded; else: loading">

  <app-client-search class="secondary-sidebar"></app-client-search>
  <app-contract-search *ngIf="(deliverySearchState$ | async)?.clients?.activeRecordGuid as clientId"
                       [clientId]="+(clientId ?? 0)"
                       class="secondary-sidebar"></app-contract-search>
  <app-ticket-search *ngIf="(deliverySearchState$ | async)?.contracts?.activeRecordGuid as contractId"
                     [contractId]="+(contractId ?? 0)" class="secondary-sidebar"></app-ticket-search>

</ng-container>

<ng-template #loading>

  <mat-progress-spinner [diameter]="35"></mat-progress-spinner>

</ng-template>
