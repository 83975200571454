import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldFlowSidebarComponent} from './field-flow-sidebar.component';
import {MatIconModule} from "@angular/material/icon";
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [
    FieldFlowSidebarComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [
    FieldFlowSidebarComponent
  ]
})
export class FieldFlowSidebarModule {
}
