import {Component, Inject, OnInit} from '@angular/core';
import {
  INFINITE_SCROLL_DATA
} from "../../../../../../shared/components/infinite-scroll-container/injection-tokens/infinite-scroll-data.injection-token";
import {LabeledDataModel} from "./models/labeled-data.model";
import {
  InfiniteScrollItemModel
} from "../../../../../../shared/components/infinite-scroll-container/models/infinite-scroll-item.model";
import {
  INFINITE_SCROLL_CONTEXT
} from "../../../../../../shared/components/infinite-scroll-container/injection-tokens/infinite-scroll-context.injection-token";
import {ScrollItemContextModel} from "./models/scroll-item-context.model";
import {Router} from "@angular/router";
import {Store} from "@ngxs/store";
import {DeliverySearchCategoryEnum} from "./enums/delivery-search-category.enum";
import {DeliverySearchStateModel} from "../../../../../../state/delivery-search/models/delivery-search.state-model";

@Component({
  selector: 'app-scroll-item',
  templateUrl: './scroll-item.component.html',
  styleUrls: ['./scroll-item.component.css']
})
export class ScrollItemComponent implements OnInit {

  constructor(@Inject(INFINITE_SCROLL_DATA) public data: InfiniteScrollItemModel<LabeledDataModel<unknown>>, @Inject(INFINITE_SCROLL_CONTEXT) public context: ScrollItemContextModel, private router: Router, private store: Store) {
  }

  ngOnInit(): void {
  }

  click(): void {
    this.context.guidSelected$.next(this.data.guid);

    const state: DeliverySearchStateModel = this.store.selectSnapshot((state) => state.deliverySearch);

    switch (this.data.item.category) {
      case DeliverySearchCategoryEnum.client:
        // We are not going to preform any actions here.
        break;
      case DeliverySearchCategoryEnum.contract:
        this.router.navigate(['delivery/contract-detail/client', state.clients!.activeRecordGuid!, 'contract', this.data.guid, 'overview']);
        break;
      case DeliverySearchCategoryEnum.ticket:
        this.router.navigate(['contracts', state.contracts!.activeRecordGuid!, 'tickets', this.data.guid, 'overview']);
        break;
    }
  }

}
