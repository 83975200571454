<d1-loading-handler [state]="loadingState">

  <ng-container *ngIf="loadingState === LoadingState.loaded">

    <app-loader></app-loader>
    <router-outlet></router-outlet>

  </ng-container>

</d1-loading-handler>
