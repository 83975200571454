import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {LoaderState} from 'src/app/models/loader/loader';

@Injectable()

export class LoaderService {

  private loaderSubject: Subject<LoaderState> = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() {
  }

  /**
   * This method is used to show a full-screen loader.
   * @deprecated This method is deprecated and will be removed in future versions.
   * We should be using mat-spinner instead.
   * LoadingHandlerComponent provides a better way to handle loading states.
   * @see LoadingHandlerComponent
   */
  show() {
    this.loaderSubject.next(<LoaderState>{show: true});
  }

  /**
   * This method is used to remove the full-screen loader.
   * @deprecated This method is deprecated and will be removed in future versions.
   * We should be using mat-spinner instead.
   * LoadingHandlerComponent provides a better way to handle loading states.
   * @see LoadingHandlerComponent
   */
  hide() {
    this.loaderSubject.next(<LoaderState>{show: false});
  }
}
