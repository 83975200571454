import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {AuthenticationStateDetails, GeneralStateModel} from "./models/general-state.model";

export class SetAuthState {
  static readonly type = '[General] Set User';

  constructor(public authState: AuthenticationStateDetails | null) {
  }
}

@State<GeneralStateModel>({
  name: 'general',
  defaults: {
    authState: null
  }
})
@Injectable({
  providedIn: 'root'
})
export class GeneralState {

  @Selector()
  static loggedInUser(state: GeneralStateModel): any | null {
    return state.authState?.user;
  }

  @Action(SetAuthState)
  setUser(ctx: StateContext<GeneralStateModel>, action: SetAuthState) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      authState: action.authState
    });
  }

}
