import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ChatSummaryModel} from "./models/chat-summary.model";
import {CitationModel} from "./models/citation.model";
import {ChatHistoryModel} from "./models/chat-history.model";

@Injectable({
  providedIn: 'root'
})
export class GeneralChatService {
  constructor(private http: HttpClient) {
  }

  query(query: string, chatHistory: ChatHistoryModel[]): Observable<ChatSummaryModel> {
    return this.http.post<ChatSummaryModel>(`${environment.copilotApiBaseUrl}/Dispatch1Copilot/GeneralChat`, {
      userQuery: query,
      role: 'user',
      chatHistory: chatHistory,
    }).pipe(
      map<any, ChatSummaryModel>((response: any) => {
        const rawChatMessages = (response.additionalDetails as any[]).flatMap(x => x.citations).map(x => {
          if (x.url === "https://core.dispatch1.com") {
            return {
              ...x,
              url: null,
            };
          }

          return x;
        });
        const citations = rawChatMessages.filter(citation => citation.url != null && !this.determineIfUrlIsFullyQualified(citation.url));
        const deepLinks = rawChatMessages.filter(citation => citation.url != null && this.determineIfUrlIsFullyQualified(citation.url)).map(citation => {
          return {
            ...citation,
            url: this.getDeepLink(citation.url),
          }
        });

        return {
          summary: response.summary,
          citations: citations.map<CitationModel>(rawCitation => {
            return {
              url: rawCitation.url,
              title: rawCitation.title,
              contentSlug: rawCitation.content != null ? `${(rawCitation.content as string).substring(0, 100)}...` : null,
            }
          }),
          deepLinks: deepLinks.map<CitationModel>(rawCitation => {
            return {
              url: rawCitation.url,
              title: rawCitation.title,
              contentSlug: rawCitation.content != null ? `${(rawCitation.content as string).substring(0, 100)}...` : null,
            }
          }),
        };
      }),
    );
  }

  private determineIfUrlIsFullyQualified(url: string): boolean {
    return url.includes('core.dispatch1.com');
  }

  private getDeepLink(url: string): string {
    return url.replace('https://core.dispatch1.com/', '/');
  }
}
